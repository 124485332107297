<!-- <div class="attachments pt-3 pointer"
              (click)="viewAttachments()"
              >
              <div class="card attachment-card">
                <div>
                  <img class="attachment-img" [src]="getFileImage()" alt="">
                </div>
                <div style="
                font-size: 10px;
                font-weight: 500;
                ">Click to view extention {{getFileExtension()}}</div>
</div>   
</div> -->

<div class="d-inline-block" style="width:200px" (click)="onClick($event)">
    <span class="tag border p-1 rounded-3 d-flex align-items-center gap-1" (click)="viewAttachments()"
>
<span class="avatar avatar-xxs tag-avatar" style="width: 20px;height: 20px;"  [style.background-image]="'url(' + getFileImage() + ')'"></span>
<span class="fs-5"><span>{{fileName}}</span></span>
<span *ngIf="removable">
  <span class="btn-close-1 ms-1 cursor-pointer fs-3" (click)="removeImage()">
    <i class="ti ti-x"></i>
  </span>
</span>

</span>
</div>