export class EntityRequest {
  companyId: string;
  siteId:string;
  facilityId:string;
}

export class EntityCompany {
  entityName: string;
  propertyType: string;
  entityCode: string;
  status: string;
  sites: Sites[];
  _id: string;
}

export class Sites {
  entityName: string;
  propertyType: string;
  status: string;
  facilities: Facility[];
  filterStatus?:boolean;
  _id: string;
  cloud:Cloud;
  id:string;

}

export class Facility {
  entityName: string;
  entityCode: string;
  propertyType: string;
  status: string;
  _id: string;
  cloud:Cloud;
}

export class EntityProfile {
  entityName: string;
  entityLogoPath: string;
  currency: string;
  propertyType: string;
  feedback: Feedback;
  analytics: Analytics;
  survey: Survey;
  marketing: Marketing;
  tripAdvisor: TripAdvisor;
  googleReview: GoogleReview;
  service: Service;
  guestEntry: GuestEntry;
  housekeeping: Housekeeping;
  review: Review;
  tableReservation: TableReservation;
  _id: string;
}

export class Feedback {
  enabled: boolean;
  type: string;
  limit: number;
}

export class Analytics {
  enabled: boolean;
}

export class Survey {
  enabled: boolean;
  limit: number;
}
export class Marketing {
  enabled: boolean;
}

export class TripAdvisor {
  enabled: boolean;
}

export class GoogleReview {
  enabled: boolean;
}

export class Service {
  enabled: boolean;
  type: string;
}

export class GuestEntry {
  enabled: boolean;
}

export class Housekeeping {
  enabled: boolean;
}

export class Review {
  enabled: boolean;
}

export class TableReservation {
  enabled: boolean;
}

export enum Cloud{
  EXPERIENCE_CLOUD='EXPERIENCE_CLOUD',
   OPERATIONAL_CLOUD='OPERATIONAL_CLOUD', 
   MARKETING_CLOUD='MARKETING_CLOUD', 
   COMMERCE_CLOUD='COMMERCE_CLOUD'
}
