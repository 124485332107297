import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './components/navigation/navigation.component';
import { AppInnerLayoutComponent } from './layouts/app-inner-layout/app-inner-layout.component';
import { AppOuterLayoutComponent } from './layouts/app-outer-layout/app-outer-layout.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    NavigationComponent,
    AppInnerLayoutComponent,
    AppOuterLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    SharedModule,
    NgbPopover
  ],
  exports:[NavigationComponent,AppInnerLayoutComponent,AppOuterLayoutComponent],
})
export class BlocksModule { }
