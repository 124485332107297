import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, forkJoin, map ,tap } from 'rxjs';
import { PropertyService } from 'src/app/core/services/property.service';
import { UserEntity } from 'src/app/modules/auth/models/auth.models';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { ListUserRequest, Team, TeamList, User } from 'src/app/modules/user/models/user.models';
import { BaseReq, UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit,OnChanges{
  @Input()  siteId:string
  @Input()  selectedUserIds:string[] = [];
  @Input()  userOption:boolean=true;
  @Input() dropdownHeight: number = 355; 

  @Output() userSelectedEvent = new EventEmitter<User>();
  @Output() teamSelectedEvent = new EventEmitter<Team>();
  @Output() otherSelectedEvent = new EventEmitter<boolean>();


  public loading:boolean=false;
  public usersList:User[];
  public user
 
  constructor(private authService:AuthService,private userService:UserService,
    private propertyService:PropertyService) { }
  

  ngOnInit(): void {
    this.getUsers()
  }



  /**
   * This function is called whenever one-way bindings are updated.
   * @param changes a dictionary of changes, with keys for each @Input() of the directive.
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedUserIds = changes['selectedUserIds']?.currentValue || this.selectedUserIds;
  }

  getUsers() {
    this.loading = true;
    const payload = new ListUserRequest();
    // payload.companyId = this.companyId;
    payload.siteId = this.siteId;
    this.loading = true;
    this.userService.getFilteredUsersList(payload).pipe(
      map(response => response.data.map(user => ({
        ...user,
        displayName: user.userProfile.displayName
      }))),
      tap(usersList => { this.usersList = usersList; })
    ).subscribe({
      next: () => {        
        this.getPropertyInfo();
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        console.log(error);
      },
    });
  }

  getPropertyInfo() {
      const detailObservables: Observable<UserEntity>[] = this.usersList.map((user) => this.propertyService.getPropertyInfo(user?.companyId, user?.siteIds?.[0], user?.facilityId))
    forkJoin(detailObservables).subscribe({
      next: (propertyInfo) => {
        propertyInfo.forEach((propertyInfo, index) => {
          this.usersList[index].userEntity = propertyInfo;
        });
      },
      error: (error) => {
        console.error(error);
      },
    });
  }




  getUserAddList() { 
    const filteredUsers = this.usersList?.filter(
      (user) => !this.selectedUserIds?.includes(user.id)
    );
    return filteredUsers;
  }



  userSelected(user: User) { this.userSelectedEvent.emit(user); }

  /**
   * Emits a team to the teamSelectedEvent subject
   * @param team the selected team
   */
  teamSelected(team: Team) { this.teamSelectedEvent.emit(team); }


  otherSelected(){
    this.otherSelectedEvent.emit(true); 
    // this.otherSelectedEvent = null;
  }

  getSelectedUser(){
    console.log(this.user);
    this.userSelected(this.user)
  }
}
