import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warning-conformation',
  templateUrl: './warning-conformation.component.html',
  styleUrls: ['./warning-conformation.component.scss']
})
export class WarningConformationComponent {
  @Input() warningSubject: string = '';
  constructor(public modal: NgbActiveModal,) {
  }

  warningModal() {
    this.modal.close('Clicked');
  }

}
