import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  showSideBar: boolean = true;

  constructor() { }
}
