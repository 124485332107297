<div class="popover-profile" sty>
    <div style="display: flex;flex-direction: column; align-items: center;">
        <ngx-avatar [name]="user?.displayName" size="30"
            [textSizeRatio]="3" [initialsSize]="3"></ngx-avatar>
        <h4>{{user?.displayName}}</h4>
        <span class="text-muted fs-6">{{user?.departmentName}}</span>
        <span class="text-muted fs-6">{{user?.position}}</span>
        <span  class="text-muted ">{{user?.designation}}</span>

    </div>
    <hr />
    <div class="profile-body d-flex flex-column align-items-center justify-content-center">
        <div>
            <span>
                <i class="ti ti-phone fs-3"></i>
            </span> {{user?.phone? (user?.phone?.countryCode? '+'+user?.phone?.countryCode : " ") + user?.phone?.phoneNumber:" - -"}} 
        </div>
        <div *ngIf="user?.email"> 
            <span>
                <i class="ti ti-mail fs-3"></i>

            </span> 
            {{user?.email?.emailId}}
        </div>
        
    </div>
</div>