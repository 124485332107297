import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ApiResponse } from '../models/common.models';
import { EMPTY, Observable, catchError, lastValueFrom, map, of, shareReplay, tap } from 'rxjs';
import { UserEntity } from 'src/app/modules/auth/models/auth.models';
import { SessionUtils } from 'src/app/shared/Utils/sessionUtils';

@Injectable({
  providedIn: 'root'
})
export class PropertyService extends BaseService {
  private inProgressRequests: { [userId: string]: Observable<UserEntity> } = {};
  constructor(private http: HttpClient) {
    super();
  }

  getEntityInfo(payload: GetEntityParams) {
    return this.http.post(`${this.gemURL}/company/property/dropdown`, payload);
  }

  getPropertyInfos(id: string) {
    return this.http.get<ApiResponse<propertyInfo>>(`${this.gemURL}/site/${id}`);
  }

  getBrandInfo(id:string){
    return this.http.get<ApiResponse<BrandInfo>>(`${this.gemURL}/brand/${id}/info`);
  }

  getCompanyInfo(id:string){
    let type='COMPANY'
    const cachedData = SessionUtils.getFromSessionStorage(type, id);
    if (cachedData) {
      return of(cachedData);
    }    
    return this.http.get<ApiResponse<UserEntity>>(`${this.gemURL}/company/${id}/info`).pipe(
      tap((response) => { SessionUtils.setToSessionStorage(type, id, response.data);}), // tap remains for side effects
      catchError((error) => {console.error(`Error fetching ${type} details: ${error}`);
        return EMPTY; // Return an empty observable on error
      }),map((response) => response.data)
    );
  }

  getSiteInfo(id:string){
    // let type='SITE'
    // const cachedData = SessionUtils.getFromSessionStorage(type, id);
    // if (cachedData) {
    //   return of(cachedData);
    // }    
    // return this.http.get<ApiResponse<UserEntity>>(`${this.gemURL}/site/${id}/info`).pipe(
    //   tap((response) => { SessionUtils.setToSessionStorage(type, id, response.data);}), // tap remains for side effects
    //   catchError((error) => {console.error(`Error fetching ${type} details: ${error}`);
    //     return EMPTY; // Return an empty observable on error
    //   }),map((response) => response.data)
    // );
    if (this.inProgressRequests[id]) {
      return this.inProgressRequests[id];
    }
  
    let type = 'SITE';
    const cachedData = SessionUtils.getFromSessionStorage(type, id);
    if (cachedData) {
      return of(cachedData);
    }
  
    const request = this.http.get<ApiResponse<UserEntity>>(`${this.gemURL}/site/${id}/info`).pipe(
      tap((response) => {
        SessionUtils.setToSessionStorage(type, id, response.data);
      }),
      catchError((error) => {
        console.error(`Error fetching ${type} details: ${error}`);
        delete this.inProgressRequests[id];
        return EMPTY; // Return an empty observable on error
      }),
      map((response) => response.data),
      shareReplay(1)
    );
  
    this.inProgressRequests[id] = request;
  
    return request;
  }

  getFacilityInfo(id:string){
    let type='FACILITY'
    const cachedData = SessionUtils.getFromSessionStorage(type, id);
    if (cachedData) {
      return of(cachedData);
    }    
    return this.http.get<ApiResponse<UserEntity>>(`${this.gemURL}/facility/${id}/info`).pipe(
      tap((response) => { SessionUtils.setToSessionStorage(type, id, response.data);}), // tap remains for side effects
      catchError((error) => {console.error(`Error fetching ${type} details: ${error}`);
        return EMPTY; // Return an empty observable on error
      }),map((response) => response.data)
    );
  }

  getBrandList(payload:GetEntityParams){
    return this.http.post<ApiResponse<BrandInfo[]>>(`${this.gemURL}/brand/list`,payload);
  }

  getPropertyInfo(companyId:string,siteId:string,facilityId:string){
  if(facilityId!=null){
    return  this.getFacilityInfo(facilityId)
  }else if(siteId!=null){
     return this.getSiteInfo(siteId)
    }
    else{
     return this.getCompanyInfo(companyId)
    }
  
  }


  // cached apis for reusable components

  getCompanyInfoCached(id:string) {
    const requestId = `COMPANY_${id}`;
    if (this.inProgressRequests[requestId]) {
      return this.inProgressRequests[requestId];
    }
    const cachedData = SessionUtils.getFromSessionStorage('COMPANY', id);
    if (cachedData) {
      return of(cachedData);
    }
    const request = this.http.get<ApiResponse<UserEntity>>(`${this.gemURL}/company/${id}/info`).pipe(
      tap((response) => { SessionUtils.setToSessionStorage('COMPANY', id, response.data);}), // tap remains for side effects
      catchError((error) => {console.error(`Error fetching COMPANY details: ${error}`);
        delete this.inProgressRequests[requestId]; 
        return EMPTY; // Return an empty observable on error
      }),map((response) => response.data),shareReplay(1)
    );
    this.inProgressRequests[requestId] = request;
    return request;
  }
  
  getFacilityInfoCached(id:string) {
    const requestId = `FACILITY_${id}`;
    if (this.inProgressRequests[requestId]) {
      return this.inProgressRequests[requestId];
    }
    const cachedData = SessionUtils.getFromSessionStorage('FACILITY', id);
    if (cachedData) {
      return of(cachedData);
    }
    const request = this.http.get<ApiResponse<UserEntity>>(`${this.gemURL}/facility/${id}/info`).pipe(
      tap((response) => { SessionUtils.setToSessionStorage('FACILITY', id, response.data);}), // tap remains for side effects
      catchError((error) => {console.error(`Error fetching FACILITY details: ${error}`);
        delete this.inProgressRequests[requestId]; 
        return EMPTY; // Return an empty observable on error
      }),map((response) => response.data),shareReplay(1)
    );
    this.inProgressRequests[requestId] = request;
    return request;
  }

  getSiteInfoCached(id:string) {
    const requestId = `SITE_${id}`;
    if (this.inProgressRequests[requestId]) {
      return this.inProgressRequests[requestId];
    }
    const cachedData = SessionUtils.getFromSessionStorage('SITE', id);
    if (cachedData) {
      return of(cachedData);
    }
    const request = this.http.get<ApiResponse<UserEntity>>(`${this.gemURL}/site/${id}/info`).pipe(
      tap((response) => { SessionUtils.setToSessionStorage('SITE', id, response.data);}), // tap remains for side effects
      catchError((error) => {console.error(`Error fetching SITE details: ${error}`);
        delete this.inProgressRequests[requestId]; 
        return EMPTY; // Return an empty observable on error
      }),map((response) => response.data),shareReplay(1)
    );
    this.inProgressRequests[requestId] = request;
    return request;
  }


  getPropertyInfoCached(companyId:string,siteId:string,facilityId:string){
    if(facilityId!=null){
      return  this.getFacilityInfoCached(facilityId)
    }else if(siteId!=null){
       return this.getSiteInfoCached(siteId)
      }
    else {
       return this.getCompanyInfoCached(companyId)
      }
    
    }
  
  

}








export class GetEntityParams {
  companyId: string;
  siteId: string;
  facilityId:string;
}

export class propertyInfo {
  entityName: string;
  entityLogoPath: string;
  companyId: string;
  id: string;
}
export class BrandInfo {
  name: string;
  entityLogoPath: string;
  companyId: string;
  id: string;
}