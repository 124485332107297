<span [style.maxWidth.px]="filterWidth">
    <!-- <a  class="btn btn-primary" data-bs-toggle="dropdown">
        <i class="ti ti-plus icon me-1 cursor-pointer"></i>
        <span class="fs-5">Add Participant</span>
    </a> -->
    <div data-bs-toggle="dropdown">
        <input type="text" [ngClass]="{ 'is-valid' : isValid == true , 'is-invalid' : isValid == false && !displaySelectedData}"
         *ngIf="!multiple" [(ngModel)]="displaySelectedData"
            class="form-control cursor-pointer dropdown-toggle" readonly [placeholder]="placeHolder" />
        <div class="select-container" *ngIf="multiple">
            <div class="value-container">
                <div class="value" *ngFor="let data of displaySelectedArray">
                    <span class="ng-value-label">{{data}}</span>
                </div>

            </div>
        </div>
    </div>
    <div class="dropdown-menu" style="width: 320px;">
        <app-user-property-dropdown [multiple]="multiple" [teamOption]="teamOption" [userOption]="userOption"
            [propertyOption]="propertyOption" [brandOption]="brandOption" [departmentOption]="departmentOption"
            [divisionId]="divisionId" [cloud]="cloud" [selectFirstItem]="selectFirstItem"
            [selectedUserIds]="selectedUserIds" [selectedTeamIds]="selectedTeamIds"
            [selectedPropertytIds]="selectedPropertytIds" [selectedBrandIds]="selectedBrandIds"
            [selectedDepartmentIds]="selectedDepartmentIds" [dropdownHeight]="dropdownHeight" [siteId]="siteId"
            [siteIds]="siteIds" [includeCompanyAdmin]="includeCompanyAdmin" [otherUserOption]="otherUserOption"
            [hasAllPropertyOption]="hasAllPropertyOption" [hasAllBrandOption]="hasAllBrandOption"
            (userSelectedEvent)="emmitUserSelectedEvent($event)" (teamSelectedEvent)="emmitTeamSelectedEvent($event)"
            (otherSelectedEvent)="emmitOtherSelectedEvent($event)"
            (propertySelectedEvent)="emmitPropertySelectedEvent($event)"
            (brandSelectedEvent)="emmitBrandSelectedEvent($event)"
            (departmentSelectedEvent)="emmitDepartmentSelectedEvent($event)"
            (alreadySelectedPropertyInfos)="setDisplayDataProperty($event)"
            (alreadySelectedUserInfos)="setDisplayDataUser($event)"
            (alreadySelectedTeamInfos)="setDisplayDataTeam($event)"
            (alreadySelectedBrandInfos)="setDisplayDataBrand($event)"
            (alreadySelectedDepartmentInfos)="setDisplayDataDepartment($event)"></app-user-property-dropdown>
    </div>
</span>