import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from 'src/app/modules/user/models/user.models';
import { UserService } from 'src/app/modules/user/services/user.service';
import { ImageService } from '../../service/image.service';

@Component({
  selector: 'app-avatar-stack',
  templateUrl: './avatar-stack.component.html',
  styleUrls: ['./avatar-stack.component.scss']
})
export class AvatarStackComponent implements OnInit,OnChanges{
  @Input() userList:User[];
  @Input() selectedList:string[];
  @Input() buttonName:string="Users";
  @Input() editable:boolean=true;
  @Output() add = new EventEmitter<string>();
  @Output() remove = new EventEmitter<string>();

  @Input() size:string = "30";
  @Input() textSizeRatio:number=3;
  @Input() initialsSize:number=3;


  public userAvatarInfoList:User[]=[];
  constructor(private userService:UserService,private imageService:ImageService){
  }
  ngOnChanges(changes: SimpleChanges): void {
    // if(changes['selectedList']){
    //   this.selectedList=changes['selectedList'].currentValue;
    //   console.log(this.selectedList);
    // }
    
  }
  ngOnInit(): void {
    this.fetchUserInfo();
  }

  addUser(userId:string){
    this.add.emit(userId);
  }

  removeUser(userId:string){
    this.remove.emit(userId);
  }

  fetchUserInfo(){
    if(this.selectedList.length>0){
      this.selectedList.forEach(userId=>{
        this.userService
        .getUserInfoCached(userId.toString())
        .subscribe({
          next:(response)=>{
          let user = response;
          user.displayName =
            user?.firstName + ' ' + (user?.lastName ? user.lastName : '-');
            this.userAvatarInfoList.push(user);  
                 
        },
          error: (error) => {
            console.error(error);
        }})
      })
      
      
    } 
  }

  getUserImage(path:string){
   return  this.imageService.getImage(path)
  }
}
