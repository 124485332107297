import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-conformation-screen',
  templateUrl: './conformation-screen.component.html',
  styleUrls: ['./conformation-screen.component.scss']
})
export class ConformationScreenComponent{
  @Input() heading : string;
  @Input() content : string;
  @Input() modalType: string;

  constructor(public modal: NgbActiveModal,){
  }

  deleteModal(){
    this.modal.close();
  }
}
