import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SubSink } from 'subsink';
import { MatCalendar, MatCalendarView } from '@angular/material/datepicker';
import DateUtils from '../../Utils/dateUtils';
import {DateRangeSelected, Ranges } from 'src/app/core/models/common.models';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import StringUtils from '../../Utils/stringUtils';

@Component({
  selector: 'app-daterange-filter',
  templateUrl: './daterange-filter.component.html',
  styleUrls: ['./daterange-filter.component.scss']
})
export class DaterangeFilterComponent implements OnInit,OnChanges{
  public Selected_Filter; 
  public Selected_CusFrom;
  public Selected_CusTo;
  public Selected_Option;
  
  
  @Output() change = new EventEmitter<DateRangeSelected>();
  @ViewChild('filter', {static: false}) filter:NgbDropdown;
  @Input() defaultRange :Ranges;
  public items: any[];

  private subs: SubSink = new SubSink();

  options:Ranges[]=[
    Ranges.TODAY,
    Ranges.YESTERDAY,
    Ranges.LAST_7_DAYS,
    Ranges.LAST_30_DAYS,
    Ranges.THIS_MONTH,
    Ranges.LAST_MONTH,
    Ranges.OVERALL,
    Ranges.CUSTOM,
  ]
  constructor(private cdr:ChangeDetectorRef) { }
  ngOnChanges(changes: SimpleChanges): void {
if(changes['defaultRange']?.currentValue){
  this.Selected_Filter=changes['defaultRange']?.currentValue;
}
}
  ngOnInit(): void {
    this.Selected_Filter=(this.defaultRange)?this.defaultRange:this.options[4];
  }

  @Input() customeRanges?:DateRangeSelected;

  ChooseCusDate(){
    if(this.Selected_CusFrom & this.Selected_CusTo){
    let obj = new DateRangeSelected
    obj.startTime=DateUtils.getStartOfDayInMillisFrom(new Date(this.Selected_CusFrom.toString()));
    obj.endTime=DateUtils.getEndOfDayInMillisFrom(new Date(this.Selected_CusTo.toString()))
    this.filter.close();
    obj.range=Ranges.CUSTOM;
    this.customeRanges=obj
    this.change.emit(obj);
    }
  }

  rangeSelected(range:Ranges){
    if(range!=Ranges.CUSTOM){
      const ranges=DateUtils.getDateRanges(range); 
      ranges.range=range;
      this.customeRanges=ranges
      this.change.emit(ranges);
      this.filter.close();
    }
    
  }

  getDisplayString(name: String) {
    return StringUtils.getDisplayNameString(name);
  }
}
