export enum SessionConstants {
    SESSION = 'instio.v3.session',
    CALLBACK_SESSION= 'instio.v1.session', 

    CURRENT_USER = 'instio.v3.currentUser',
    CALLBACK_CURRENT_USER = 'instio.v1.currentUser',

    ENTITY_COMPANY = 'instio.v3.select.entity.company',
    CALLBACK_ENTITY_COMPANY = 'instio.v1.select.entity.company',

    ENTITY_PROFILE = 'instio.v3.select.entity.profile',
    CALLBACK_ENTITY_PROFILE = 'instio.v1.select.entity.profile',

    CHAT_SELECTED = 'instio.v3.chat.selected',
    CALLBACK_CHAT_SELECTED = 'instio.v1.chat.selected',

    USERS_LIST = 'instio.v3.userslist' ,
    CALLBACK_USERS_LIST = 'instio.v1.userslist',
    
    ENTITY = 'instio.v3.user.entity',

}