export class CurrentNotification {
    templateName: string;
    description:  string;
    contentType:  ContentType=ContentType.TEXT;
    from =   new sendErDetails();
    replyTo= new sendErDetails();
    tags:         string[];
    subject:      string;
    lang:         string;
    content:      string;
    companyId:    string;
    htmlPath?:    string;
}

export enum ContentType{
    TEXT= 'TEXT',
    HTML='HTML',
    PATH_HTML='PATH_HTML'  
}
export class sendErDetails {
    name:    string;
    emailId: string;
}

export class LanguageDetails {
    name:       string;
    nativeName: string;
    code:       string;
}

export const EMAIL_TYPE_TAGS=[
    { key: 'Promoter', value: 'PROMOTER' },
    { key: 'Passive', value: 'PASSIVE' },
    { key: 'Detractor', value: 'DETRACTOR' },
    { key: 'Birthday', value: 'BIRTHDAY' },
    { key: 'Anniversary', value: 'ANNIVERSARY' },
    { key: 'Check-in', value: 'CHECKIN' },
    { key: 'Check-out', value: 'CHECKOUT' },
    { key: 'Booking', value: 'BOOKING' },
    { key: 'Archive', value: 'ARCHIVE' },
    { key: 'Survey', value: 'SURVEY' },
    { key: 'Campaign', value: 'CAMPAIGN' },
    { key: 'Other', value: 'OTHER' }
]


export class Properties{
    _id:          string;
    entityName:   string;
    propertyType: string;
    entityCode:   null | string;
    status:       string;
    sites?:       Properties[];
    facilities?:  Facility[];
    divisionId : string;
}

export class Facility {
    _id:          string;
    entityName:   string;
    propertyType: string;
    status:       string;
}
