import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sparkline-chart',
  templateUrl: './sparkline-chart.component.html',
  styleUrls: ['./sparkline-chart.component.scss']
})
export class SparklineChartComponent  implements OnInit{
 
  @Input() value1: number;
  @Input() value2: number;

  maxValue:number



  ngOnInit(): void {
    if(this.value1!=null && this.value2!=null){ 
      this.maxValue = Math.max(this.value1, this.value2);
      
    }  }


  getBarHeight(value: number): string {
    
    if (this.maxValue == null || this.maxValue === 0) {
      return '0%';
    }
    return this.getPercentage(value) + '%';
  }


  getPercentage(value: number): number {
    return (value / this.maxValue) * 100;
  }

}
