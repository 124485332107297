import { Component } from '@angular/core';

@Component({
  selector: 'app-app-outer-layout',
  templateUrl: './app-outer-layout.component.html',
  styleUrls: ['./app-outer-layout.component.scss']
})
export class AppOuterLayoutComponent {

}
