<ng-container>
    <ng-select [items]="usersList" placeholder="Select User" [(ngModel)]="user" (change)="getSelectedUser()" bindLabel="displayName"
        [bindValue]="user">
        <ng-template ng-option-tmp let-user="item" let-index="index" let-search="searchTerm">
            <div class="row align-items-center" style="width: 100%;">
                <div class="col-auto">
                    <ngx-avatar size="30" [textSizeRatio]="3" [initialsSize]="3" [name]="user.displayName"
                        class="my-img"></ngx-avatar>
                </div>
                <div class="col">
                    <div class="fw-bold fs-5" style="white-space: break-spaces;">
                        {{user?.displayName}}
                    </div>
                    <div class="fs-5 text-truncate">
                        {{user?.email?.emailId}}
                    </div>
                    <div
                        class="tags-list text-secondary fs-6 flex-wrap d-flex align-items-center justify-content-start gap-1">
                        <span class="tag d-flex align-items-center " *ngIf="user?.departmentName"><i
                                class="ti ti-tournament me-1"></i><span
                                class="text-truncate">{{user?.departmentName}}</span></span>
                        <span class="tag d-flex align-items-center " *ngIf="user?.positionName"><i
                                class="ti ti-user-pin me-1"></i><span
                                class="text-truncate">{{user?.positionName}}</span></span>
                    </div>
                </div>
                <div class="col-auto">
                    <span class="avatar rounded-circle"
                        *ngIf="user?.userEntity?.entityCode">{{user?.userEntity?.entityCode}}</span>
                </div>
            </div>
        </ng-template>
    </ng-select>
</ng-container>