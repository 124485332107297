import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, forkJoin, map ,tap } from 'rxjs';
import { PropertyService } from 'src/app/core/services/property.service';
import { UserEntity } from 'src/app/modules/auth/models/auth.models';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { ListUserRequest, Team, TeamList, User } from 'src/app/modules/user/models/user.models';
import { BaseReq, UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss']
})
export class UserDropdownComponent implements OnInit,OnChanges {
  @Input()  siteId:string
  @Input()  selectedUserIds:string[] = [];
  @Input()  selectedTeamIds:string[] = [];
  @Input()  otherUserOption:boolean=false;
  @Input()  userOption:boolean=true;
  @Input()  teamOption:boolean=false;
  @Input()  brandOption:boolean=false;
  @Input()  dropdownHeight: number = 355; 
  @Output() userSelectedEvent = new EventEmitter<User>();
  @Output() teamSelectedEvent = new EventEmitter<Team>();
  @Output() otherSelectedEvent = new EventEmitter<boolean>();

  public dropDownSwitchType:string;
  public loading:boolean=false;
  public usersList:User[];
  public teamsList:Team[];
 
  constructor(private authService:AuthService,private userService:UserService,
    private propertyService:PropertyService) { }
  

  ngOnInit(): void {
    if (this.userOption && this.teamOption) {
      this.dropDownSwitchType = 'USER';
    }
    else if(this.userOption) {
      this.dropDownSwitchType = 'USER';
    }
     else if(this.teamOption) {
      this.dropDownSwitchType = 'TEAM';
    }

      this.getUsersOrTeams();
   
  }

  private getUsersOrTeams() {
    if (this.dropDownSwitchType === 'USER') {
      this.getUsers();
    } else {
      this.getTeams();
    }
  }

  /**
   * This function is called whenever one-way bindings are updated.
   * @param changes a dictionary of changes, with keys for each @Input() of the directive.
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedUserIds = changes['selectedUserIds']?.currentValue || this.selectedUserIds;
  }

  getUsers() {
    this.loading = true;
    const payload = new ListUserRequest();
    // payload.companyId = this.companyId;
    payload.siteId = this.siteId;
    this.loading = true;
    this.userService.getFilteredUsersList(payload).pipe(
      map(response => response.data.map(user => ({
        ...user,
        displayName: user.userProfile.displayName
      }))),
      tap(usersList => { this.usersList = usersList; })
    ).subscribe({
      next: () => {        
        this.getPropertyInfo();
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        console.log(error);
      },
    });
  }

  getPropertyInfo() {
      const detailObservables: Observable<UserEntity>[] = this.usersList.map((user) => this.propertyService.getPropertyInfo(user?.companyId, user?.siteIds?.[0], user?.facilityId))
    forkJoin(detailObservables).subscribe({
      next: (propertyInfo) => {
        propertyInfo.forEach((propertyInfo, index) => {
          this.usersList[index].userEntity = propertyInfo;
        });
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  getTeams() {
    let payload = new BaseReq();
    this.userService.teamsDropdown(payload).subscribe({
      next: (res) => this.teamsList = res.data,
      error: (err) => console.log(err),
      complete: () => {}, // avoids an unnecessary subscription to the error channel.   
    });
  }

  dropDownSwitch(type: string) {
    this.dropDownSwitchType = type;
  }

  findValue(searchValue: string): any {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    if (this.dropDownSwitchType == 'USER') {
      return this.getUserAddList()?.filter(item => item?.displayName.toLowerCase().includes(lowerCaseSearchValue));
    }
    if (this.dropDownSwitchType == 'TEAM') {
      return this.getUnitAddList()?.filter(item => item?.name.toLowerCase().includes(lowerCaseSearchValue));
    }
    return [];
  }

  getUserAddList() { 
    const filteredUsers = this.usersList?.filter(
      (user) => !this.selectedUserIds?.includes(user.id)
    );
    return filteredUsers;
  }

  getUnitAddList() { 
    const filteredTeams = this.teamsList?.filter(
      (user) => !this.selectedTeamIds?.includes(user.id)
    );
    return filteredTeams;
  }

  userSelected(user: User) { this.userSelectedEvent.emit(user); }

  /**
   * Emits a team to the teamSelectedEvent subject
   * @param team the selected team
   */
  teamSelected(team: Team) { this.teamSelectedEvent.emit(team); }


  otherSelected(){
    this.otherSelectedEvent.emit(true); 
    // this.otherSelectedEvent = null;
  }
}
