import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-template-select',
  templateUrl: './common-template-select.component.html',
  styleUrls: ['./common-template-select.component.scss']
})
export class CommonTemplateSelectComponent implements OnInit{
  @Input() currentHeader: string;

  constructor(private router: Router,
    public modal: NgbActiveModal,){

  }
  ngOnInit(): void {
  }
  navigateToNotificatonTemplate(notification: string) {
    if(notification == 'email') this.router.navigate(['/configuration-settings/email-choose'])
    if(notification == 'sms') this.router.navigate(['/configuration-settings/sms-choose'])
    this.modal.close();
  }

}
