import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { LanguageDetails } from '../models/notification.models';
import { ApiResponse } from 'src/app/core/models/common.models';
import { SessionConstants } from 'src/app/core/constants/session.constants';
import { ListUserRequest, User } from 'src/app/modules/user/models/user.models';

@Injectable({
  providedIn: 'root'
})
export class CommonuseService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getLanguages() {
    return this.http.get<ApiResponse<LanguageDetails[]>>(this.oldSiteURL + '/assets/json/languageCode.json');
  }

  uploadHtml(form: FormData) {
    return this.http.post<ApiResponse<string>>(`${this.gemURL}/instio/upload/html`, form);
  }

  getMonthFromDate(date: string): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = parseInt(date.split('-')[1]) - 1;
    return months[monthIndex];
  }

  getDayFromDate(date: string): string {
    return date.split('-')[2];
  }

  convertDateString(dateString: string): string {
    if (!dateString) return '';

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  private padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }


  formatDate(dateString: string): string {
    const currentDate = new Date(); // Current date and time
    const postDate = new Date(dateString); // Date from the provided string

    let timeDifference = currentDate.getTime() - postDate.getTime();

    // If time difference is negative, set it to 0
    timeDifference = Math.max(timeDifference, 0);

    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (minutesDifference < 60) {
      return `about ${minutesDifference} mins ago`;
    } else if (hoursDifference < 24) {
      return `about ${hoursDifference} hour ago`;
    } else {
      const formattedDate = this.formatDateToString(postDate);
      return `commented on ${formattedDate}`;
    }
  }

  formatDateToString(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = date.toLocaleTimeString('en-US', options);
    const formattedDate = date.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });

    return `${formattedDate} ${formattedTime}`;
  }


}
