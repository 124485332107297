<div class="page-header d-print-none marginbot">
    <div class="container-xl">
      <div class="row g-2 align-items-center px-2">
        <div class="col-md-12">
          <h3>New SMS Template</h3>
        </div>
        
        
  
        
      </div>
  
  <div class="row mt-2">
    <div class="col-md-12">
      <div class="card">
        
        <div class="card-body">
          <div class="row">
            <div class="col-md-4 col-sm-6 mb-3">
              <label class="form-label required">Template Name</label>
              <input type="text" class="form-control" name="example-text-input" placeholder="Input placeholder">
            </div>
            <div class="col-md-4 col-sm-6 mb-3">
              <label class="form-label ">Description</label>
              <input type="text" class="form-control" name="example-text-input" placeholder="Input placeholder">
            </div>
            <div class="col-md-4 col-sm-6 mb-3">
              <label class="form-label required">Sender</label>
              <input type="text" class="form-control" name="example-text-input" placeholder="Input placeholder">
            </div>
            <div class="col-md-4 col-sm-6 mb-3">
              <label class="form-label">Provider</label>
              <select class="form-select">
                <option value="1">Two_factor</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div class="col-md-4 col-sm-6 mb-3">
              <label class="form-label">Language</label>
              <select class="form-select">
                <option value="1">Choose Language</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div class="col-md-4 col-sm-6 mb-3">
              <label class="form-label required">Add Tag </label>
              <select class="form-select">
                <option value="1">Choose a tag</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
  <div class="col-md-12 mb-3">
   
      <label class="form-label  required">Enter SMS Content Here</label>
      <textarea class="form-control" data-bs-toggle="autosize" placeholder="Type something…" style="overflow: hidden; overflow-wrap: break-word; resize: none; height: 56px;"></textarea>
    
  </div>
  
          </div>
  
          
  
  
            <div class="row mt-2">
              <hr/>
            </div>
            <div class="row mt-2">
            <div class="col-md-12">
                <a href="#" class="btn btn-primary float-right w-auto">
                  Save
                </a>
              </div>
            </div>
  
        </div>
      </div>
    </div>
  </div>
  
  
  
  </div>
  </div>
      
        
  
  
  
  
  
  
  