import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: number | string): string {
    const date = typeof value === 'string' ? new Date(value).getTime() : value;
    const now = new Date().getTime();
    const seconds = Math.floor((now - date) / 1000);
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1
    };

    let counter;
    for (const interval in intervals) {
      if (seconds >= intervals[interval]) {
        counter = Math.round(seconds / intervals[interval]);
        return `${counter} ${interval}${counter > 1 ? 's' : ''} ago`;
      }
    }
    return 'Just now';
  }

}
