import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-universal-file-viewer',
  templateUrl: './universal-file-viewer.component.html',
  styleUrls: ['./universal-file-viewer.component.css'],
})
export class UniversalFileViewerComponent implements OnInit {
  @Input() url: any;
  @Input() fileName: string;
  @Input() title: string;
  @Input() canDownload: boolean = false;
  extension: string;
  constructor(public modal: NgbActiveModal, private httpClient: HttpClient) {
    this.extension = this.getFileExtension(this.fileName);
  }

  ngOnInit() {
    console.log('here');
  }
  close() {
    this.modal.dismiss();
  }
  isVideo(extension) {
    if (
      extension == 'mp4' ||
      extension == 'MOV' ||
      extension == 'MKV' ||
      extension == 'AVI' ||
      extension == 'webm'
    ) {
      return true;
    } else {
      return false;
    }
  }

  isImage(extension) {
    if (
      extension == 'jpeg' ||
      extension == 'jpg' ||
      extension == 'png' ||
      extension == 'svg' ||
      extension == 'gif' ||
      extension == 'webp'
    ) {
      return true;
    } else {
      return false;
    }
  }

  getFileExtension(fileName: string): string {
    if (fileName != null) {
      const parts = fileName.split('.');
      const extension = parts[parts.length - 1];
      return extension;
    }
    return null;
  }

  downloadAttachments() {
    this.httpClient.get(this.url, { responseType: 'blob' }).subscribe((res) => {
      const fileName = this.fileName;
      const downloadUrl = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.target = '_blank'; // Open in a new tab
      link.setAttribute('download', fileName); // Optional: Set the download attribute for fallback
      link.click();
    });
  }
}
