import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PropertyService } from 'src/app/core/services/property.service';
import { UserEntity } from 'src/app/modules/auth/models/auth.models';

@Component({
  selector: 'app-location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.scss'],
})
export class LocationInfoComponent implements OnInit, OnChanges {
  @Input() companyId: string;
  @Input() siteId: string;
  @Input() facilityId: string;
  @Input() fontSize: string;
  @Input() color: string;
  @Input() styleClasses: string[];
  @Input() shortForm:boolean=false;
  @Input() hideIcon:boolean=false;
  entity: UserEntity;

  constructor(private propertyService: PropertyService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyId'] || changes['siteId'] || changes['facilityId']) {
      this.getEntityInfo();
    }
  }

  ngOnInit(): void {}

  getEntityInfo() {
    this.propertyService
      .getPropertyInfoCached(this.companyId, this.siteId, this.facilityId)
      .subscribe({
        next: (res) => {
          this.entity = res;  
        },
        error: (err) => {
          console.log(err);
        },
      });
  }
}
