import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Division, DivisionCreateRequest, DivisionsFilter } from '../models/division.models';
import { ApiPaginationResponse, ApiResponse } from 'src/app/core/models/common.models';
import { BYPASS_INJECTION } from 'src/app/core/interceptors/common.interceptor';

@Injectable({
  providedIn: 'root'
})
export class DivisionService extends BaseService {

  constructor(private http: HttpClient) {super() }

  createDivision(payload:DivisionCreateRequest){
    return this.http.post<ApiResponse<any>>(`${this.gemURL}/division`,payload)
  }
  updateDivision(payload:DivisionCreateRequest,id:string){
    return this.http.put<ApiResponse<any>>(`${this.gemURL}/division`,payload)
  }
  getDivision(id:string){
    return this.http.get<ApiResponse<Division>>(`${this.gemURL}/division/${id}/info`)
  }
  deleteDivision(id:string){
    return this.http.delete<ApiResponse<Division>>(`${this.gemURL}/division/${id}`,{ context: new HttpContext().set(BYPASS_INJECTION, true) })

  }

  listDivisions(payload:DivisionsFilter){
    return this.http.post<ApiResponse<Division[]>>(`${this.gemURL}/division/dropdown`,payload)
  }
  searchDivisions(payload:DivisionsFilter){
    return this.http.post<ApiPaginationResponse<Division[]>>(`${this.gemURL}/division/search`,payload)
  }

}
