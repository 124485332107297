<!-- <div class="dropdown-menu dropdown-menu-arrow" style="width: 320px;"> -->
    <ng-container >
    <div class="d-flex" *ngIf="userOption && teamOption">
        <a (click)="getUsers();dropDownSwitch('USER');$event.stopPropagation()"
            class="card-btn drop-btn"><!-- Download SVG icon from http://tabler-icons.io/i/mail -->
            <i class=" border-icon ti ti-user  cursor-pointer"></i></a>
        <a (click)="getTeams();dropDownSwitch('TEAM');$event.stopPropagation();"
            class="card-btn drop-btn"><!-- Download SVG icon from http://tabler-icons.io/i/phone -->
            <i class="border-icon ti ti-users-group  cursor-pointer"></i></a>
    </div>
    <a class="dropdown-item">
        <input class="me-2 ms-2 m-2" value='' type="text"
            [placeholder]="dropDownSwitchType=='USER'?'search user...':'search team...'" #search class="form-control"
            (input)="findValue(search.value)">
    </a>
    <div class="drop-box"  [style.maxHeight.px]="dropdownHeight">
        <ng-container *ngIf="dropDownSwitchType=='USER'">
            <a class="dropdown-item cursor-pointer" *ngFor="let user of findValue(search.value)"
                (click)="userSelected(user);search.value = ''">
                <div class="row align-items-center" style="width: 100%;">
                    <div class="col-auto">
                        <ngx-avatar size="30" [textSizeRatio]="3" [initialsSize]="3" [name]="user.displayName"
                            class="my-img"></ngx-avatar>
                    </div>
                    <div class="col">
                        <div class="fw-bold fs-5" style="white-space: break-spaces;">
                            {{user?.displayName}}

                        </div>
                        <div class="fs-5 text-truncate" >
                            {{user?.email?.emailId}}</div>
                        <div
                            class="tags-list text-secondary fs-6 flex-wrap d-flex align-items-center justify-content-start gap-1">
                            <span class="tag d-flex align-items-center " *ngIf="user?.departmentName"><i
                                    class="ti ti-tournament me-1"></i><span class="text-truncate">{{user?.departmentName}}</span></span>
                            <span class="tag d-flex align-items-center " *ngIf="user?.positionName"><i
                                    class="ti ti-user-pin me-1"></i><span class="text-truncate">{{user?.positionName}}</span></span>
                        </div>
                    </div>
                    <div class="col-auto">
                        <span class="avatar rounded-circle"
                            *ngIf="user?.userEntity?.entityCode">{{user?.userEntity?.entityCode}}</span>
                    </div>
                </div>
                <!-- <span class="avatar avatar-sm rounded-circle"><ngx-avatar size="30"
            [textSizeRatio]="3" [initialsSize]="3" [name]="user.displayName"
            class="my-img"></ngx-avatar>
    </span>

    <span class="ms-1">{{user.displayName}}</span> -->
            </a>
            <a *ngIf="otherUserOption" class="dropdown-item" (click)="otherSelected();search.value = ''">
                <span class="avatar avatar-sm rounded-circle"><ngx-avatar facebookId="1508319875" size="30"
                        [textSizeRatio]="3" [initialsSize]="3" class="my-img"></ngx-avatar>
                </span>

                <span style="margin-left: 1rem;">Other</span>
            </a>
        </ng-container>
        <ng-container *ngIf="dropDownSwitchType=='TEAM'">
            <a class="dropdown-item" *ngFor="let team of findValue(search.value)"
                (click)="teamSelected(team);search.value = ''">
                <div class="row align-items-center">
                    <div class="col-auto">
                        <span class="avatar rounded-circle"><i
                                class=" fs-3 ti ti-users-group  cursor-pointer"></i></span>
                    </div>
                    <div class="col">
                        <div class="font-weight-medium">
                            {{team?.name}}
                        </div>
                        <div class="text-secondary">
                            Users
                        </div>
                    </div>
                    <div class="col-auto">
                    </div>
                </div>
            </a>
        </ng-container>
    </div>

</ng-container>

<!-- </div> -->