import { Role, Source } from "src/app/core/models/common.models";

export class LoginRequest {
  username: string;
  password: string;
  source: string = Source.WEB;
  sessionId: string;
}

export class LogoutRequest{
  companyId:string;
  facilityId:string;
  siteId:string;
}

export class UserSession {
  adminFor: string;
  entityAddedOn: Date;
  currentProfileId:string;
  entityType: string;
  expireTime: string;
  name: string;
  role: Role;
  userName: string;
  userToken: string;
  adminForId: string;
  companyId: string;
  sessionId: string;
  userId: string;
}
export class UserEntity {
  status: string
  addedBy: string
  addedOn: number
  lastUpdated: number
  entityName: string
  entityCode:string;
  userName: string
  entityLogoPath: string
  entityAddress: EntityAddress
  communication: Communication
  propertyType: string
  timeZone: string
  brandId: string
  singleProperty: boolean
  id: string
  currency: string
}

export class EntityAddress {
  addressLine1: string
  addressLine2: string
  district: string
  state: string
  country: string
  formatted: string
}

export class Communication {
  email: Email
}

export class Email {
  emailId: string
  valid: boolean
}

  export class SwitchProfileRequest {
    sessionId: string;
    token: string;
    userId: string;
    profileId: string;
  }

  export class RegisterPayload {
    propertyType: string
    name: string
    email: string
    phone: string
    entityName: string
  }

