<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Choose a <span>{{currentHeader}}</span> template</h5>
    </div>
    <div class="modal-body">


       


        <div class="col-12 center-align mb-2">
            No Records Found
        </div>
        <div class="col-12 center-align">
            <a class="btn btn-primary w-auto" (click)="navigateToNotificatonTemplate(currentHeader)"> Choose New Template</a>
        </div>


    </div>


    <div class="modal-footer">
        <a
        
        class="btn btn-primary w-auto float-right
        mt-4"
    >
        Cancel
    </a>
        
    </div>
</div>
