import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { User } from 'src/app/modules/user/models/user.models';
import { UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit, OnChanges {
  @Input() userId: string;
  @Input() fontSize: string;
  @Input() color: string;
  @Input() showPopover: boolean = true;
  @Input() styleClasses: string[];
  @Input() userAvatar: boolean = false;
  userInfo: User;

  constructor(private userService: UserService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId']) {
      this.fetchUserInfo();
    }
  }

  ngOnInit(): void {
    // this.fetchUserInfo();
  }

  fetchUserInfo() {
    if (this.userId && this.userId !== '-1') {
      this.userService.getUserInfoCached(this.userId?.toString()).subscribe({
        next: (response) => {
          if (response) {
            let user = response;
            user.displayName = user?.firstName + ' ' + (user?.lastName ? user.lastName : '');
            user.shortName = user?.firstName[0] + (user?.lastName ? user.lastName[0] : '');
            this.userInfo = user;
            // console.log(this.userInfo);

          }
          
        },
        error: (error) => {
          console.error(error);
        }
      })

    }
  }
}
