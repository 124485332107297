import { Role } from 'src/app/core/models/common.models';
import { BaseService } from 'src/app/core/services/base.service';

export default class StringUtils {
  static getRandomString(length: number = 60) {
    var randomChars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }
  static getDisplayNameString(nameString: any) {
    const formattedNameString = nameString.toLowerCase().split('_');
    for (let i = 0; i < formattedNameString.length; i++) {
      formattedNameString[i] =
        formattedNameString[i].charAt(0).toUpperCase() +
        formattedNameString[i].substring(1);
    }
    return formattedNameString.join(' ');
  }
  static formatSizeUnits(bytes: number): string {
    if (bytes >= 1073741824) {
      return (bytes / 1073741824).toFixed(2) + ' GB';
    } else if (bytes >= 1048576) {
      return (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes > 1) {
      return bytes + ' bytes';
    } else if (bytes === 1) {
      return bytes + ' byte';
    } else {
      return '0 bytes';
    }
  }

  static formatRoleDisplayName(role) {
    if (role === Role.COMPANY_ADMIN || role === Role.COMPANY_USER) {
      return 'Administrator';
    } else {
      return this.getDisplayNameString(role);
    }
  }

  static getFileExtension(fileName: string): string {
    if (fileName != null) {
      const parts = fileName.split('.');
      const extension = parts[parts.length - 1];
      return extension;
    }
    return null;
  }

  static getFileIcon(type) {
    switch (type) {
      case 'pdf': {
        return 'assets/images/announcement/pdf.png';
      }
      case 'docx': {
        return 'assets/images/announcement/DOC.png';
      }
      case 'xls': {
        return 'assets/images/announcement/exel.png';
      }
      case 'png': {
        return 'assets/images/announcement/IMAge.png';
      }
      case 'jpg': {
        return 'assets/images/announcement/IMAge.png';
      }
      case 'jpeg': {
        return 'assets/images/announcement/IMAge.png';
      }
      case 'mp4': {
        return 'assets/images/announcement/video.png';
      }
      case 'mkv': {
        return 'assets/images/announcement/video.png';
      }
      case 'avi': {
        return 'assets/images/announcement/video.png';
      }
      case 'webm': {
        return 'assets/images/announcement/video.png';
      }
      case 'null': {
        return '';
      }
      default: {
        return 'assets/images/announcement/unknown.png';
      }
    }
  }

  static compareStrings(str1: string, str2: string): boolean {
    // Normalize both strings by converting to lowercase and removing '_' and '.'
    const normalizeString = (str: string) =>
      str.toLowerCase().replace(/[_\.]/g, '');

    // Compare the normalized strings
    return normalizeString(str1) === normalizeString(str2);
  }
}
