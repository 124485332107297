<nav>
    <ul class="pagination">
      <li class="page-item" [class.disabled]="currentPage === 1">
        <a class="page-link"  (click)="previousPage()">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M15 6l-6 6l6 6"></path></svg>
          prev
        </a>
      </li>
      <li class="page-item" *ngFor="let page of pages"
          [class.active]="currentPage === page">
        <a class="page-link"  (click)="goToPage(page)">{{ page }}</a>
      </li>
      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <a class="page-link"  (click)="nextPage()">
          next
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 6l6 6l-6 6"></path></svg>
        </a>
      </li>
    </ul>
</nav>