import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonInterceptor } from './interceptors/common.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],providers: [ {
    provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor,multi: true
  },{
    provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor,multi: true
  }],
})
export class CoreModule { }
