import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService extends BaseService{

  constructor(private http: HttpClient) {
    super();
   }

  getImage(path:string){
    return  this.imgURL+  'api/instio/images/' + path;
  }




  }