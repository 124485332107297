import { Pipe, PipeTransform } from '@angular/core';
import DateUtils from '../Utils/dateUtils';

@Pipe({
  name: 'dueDate'
})
export class DueDatePipe implements PipeTransform {

  transform(dueDate: number): string {
    if (dueDate === null || dueDate === undefined) {
      return '';
    }
    const currentDate = new Date();
    const dueDateObject = new Date(dueDate);

    const timeDifference = dueDateObject.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      return 'Due today';
    } else if (daysDifference === 1) {
      return 'Due in 1 day';
    } else if (daysDifference < 0) {
      return 'Due date passed';
    } else {
      const formattedDate = DateUtils.toLocalDateWithFormat(dueDateObject,'dd-MM-yyyy')
      return `Due in ${daysDifference} days (Due on ${formattedDate})`;
    }
  }

}
