import { Component, OnInit } from '@angular/core';
import { ContentType, CurrentNotification, EMAIL_TYPE_TAGS, LanguageDetails, sendErDetails } from '../../models/notification.models';
import { CommonuseService } from '../../service/commonuse.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UserService } from 'src/app/modules/user/services/user.service';
import { BaseService } from 'src/app/core/services/base.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent extends BaseService implements OnInit {
emailTemplate:CurrentNotification;
languageDetails: LanguageDetails[] = [];
switchEmailEditor: boolean = true;
tagsTemp=EMAIL_TYPE_TAGS;
public ckEditor= ClassicEditor;
submitClicked:boolean=false;
type= ContentType;

constructor(private commonUseService: CommonuseService, private sanitizer: DomSanitizer
  
  ){super()
}

ngOnInit() {
  this.setDefaults();
  this.getLanguages();
}

setDefaults(){
  this.emailTemplate=new CurrentNotification();
  this.emailTemplate.content="";
  this.emailTemplate.from=new sendErDetails();
  this.emailTemplate.replyTo=new sendErDetails();

}
getLanguages(){
  this.commonUseService.getLanguages().subscribe({
    next:(response)=>{
    },
    error:(error)=>{
      console.log(error);
    }
  });
}

processImage(event: File) {
  if (event) {
    this.uploadFile(event);
  }
}



uploadFile(file: File) {
  const form = new FormData();
  form.append('file', file);
  this.commonUseService.uploadHtml(form).subscribe({
    next: (response) => {
      this.emailTemplate.htmlPath = response.data;
    },
    error: (error) => {
      console.log("error");
    },
  });

}

getFile() {
  return this.emailTemplate.htmlPath
    ? this.imgURL + 'api/instio/html/' + this.emailTemplate.htmlPath
    : this.emailTemplate.htmlPath;
}

getPreviewFrame(){
  return this.sanitizer.bypassSecurityTrustResourceUrl(
    this.emailTemplate.htmlPath
   ? this.imgURL + 'api/instio/html/' + this.emailTemplate.htmlPath
   : ""
   );
}

addCustomRootCause = (term) =>(term);

validate(){
  this.submitClicked=true;

}

saveEmailTemplate(){
  
}
}
