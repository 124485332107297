export class RRPOverview {
  totalRoomNights: number;
  forecastedRoomNights: number;
  adr: number;
  occupancy: number;
  revenue: number;
  revPar:number;
 
}

export class RRPStatement {
    totalRoomNights: number;
    adr: number;
    occupancy: number;
    revenue: number;
    soldRoomNights: number;
    revPar:number;
    trendInfo: TrendInfo;
  }

  export class TrendInfo {
    trend?: any;
    totalRoomNights: number;
    soldRoomNights: number;
    adr: number;
    revPar: number;
    occupancy: number;
    revenue: number;
   adrTrend: number;
   availableRoomsTrend: number;
   soldRoomsTrend: number;
   occupancyTrend: number;
   revParTrend: number;
  }

  export class IncomeExpenseOverview {
    name: string
    code: string
    lastYear: number
    budget: number
    actual: number
    budgetVsActual: number;
    lastYearVsActual: number;
  }

  export class CommonRatiosDto {
    id:          number;
    category:    string;
    name:        string;
    description: string;
    sum:         number;
    avg:         number;
    gopPercentage:any;
  lastYearSum: number
  lastYearAvg: number
 
}

export class LegalEntity {
  status: string;
  addedBy: string;
  addedOn: number;
  name: string;
  companyId: string;
  id: string;
  
}

export class OperationalStatement {
  groupId: number;
  name: string;
  sequence: number;
  amount: number;
  lastYear: number;
  hoaStatements: HoaStatement[];
  color: string;
  addHoaStatements: HoaStatement[];
  lessHoaStatements: HoaStatement[];
  groupStatement: GroupStatement;
  budgetVsActual: number;
  lastYearVsActual: number;
}
export class HoaStatement {
  hoaId: number;
  name: string;
  sequence: number;
  amount: number;
  lastYear: number;
  code: string;
  accountable: number;
  budgetVsActual: number;
  lastYearVsActual: number;
}

export class GroupStatement {
  amount: number;
  color: string;
  lastYear: number;
  name: string;
  budgetVsActual: number;
  lastYearVsActual: number;
}

export class KPIs {
  adr: number;
  revPar: number;
  occupancy: number;
  cpor: number;
  alos: number;
  gopPar: number;
  revPor: number;
  arg: number;
  apgpd: number;
  profPar: number;
  trevPar: number;
  nrevPar: number;
  trevPec: number;
}

export class OmmitenceData {
  id: number
  name: string
  year: number
  month: number
  companyId: string
  siteId: string
}


export enum OwnerShipTypes{
  MANAGED="MANAGED",
  OWNED="OWNED",
  LEASED ="LEASED"
}

export const OwnerShipFilters = [
  { label: 'Managed', value: OwnerShipTypes.MANAGED ,status:false },
  { label: 'Owned', value: OwnerShipTypes.OWNED,status:false },
  { label: 'Leased', value: OwnerShipTypes.LEASED,status:false },
];
