import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
@Component({
  selector: 'app-app-inner-layout',
  templateUrl: './app-inner-layout.component.html',
  styleUrls: ['./app-inner-layout.component.scss']
})
export class AppInnerLayoutComponent implements OnInit {

  constructor(private commonService:CommonService,private breakpointObserver: BreakpointObserver){}
  ngOnInit(): void {
    this.breakpointObserver.observe(
      "(max-width: 991px)"
    ).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.commonService.showSideBar=true;   
      } 
    });
  }
  showSideNav(){
    return this.commonService.showSideBar;
  }
  unHideNav(){
    this.commonService.showSideBar=!this.commonService.showSideBar
  }

}


