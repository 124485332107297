import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
type ValueType = 'PERCENTAGE' |  'NUMBER'| 'AMOUNT';

@Component({
  selector: 'app-percentage-indicator',
  templateUrl: './percentage-indicator.component.html',
  styleUrls: ['./percentage-indicator.component.scss']
})
export class PercentageIndicatorComponent implements OnInit , OnChanges{
  @Input() percentValue:number;
  @Input() roundDecimalPoints:number = 2;
  @Input() type:ValueType='PERCENTAGE';
  @Input() styleClasses:string[]=['fs-5'];
  @Input() reverseColorStatus:boolean=false;

  ngOnChanges(changes: SimpleChanges): void {
    if(this.percentValue) {
      this.percentValue = Number(this.percentValue.toFixed(this.roundDecimalPoints));
    }
  }
  ngOnInit(): void {

  }

  getColorClass() {
    if(this.percentValue>0) {
      return this.reverseColorStatus?'text-red':'text-green';
    } else if(this.percentValue<0) {
      return this.reverseColorStatus?'text-green':'text-red';
    } else {
      return 'text-secondary';
    }
  }



}
