<div class="page-header d-print-none marginbot">
  <div class="container-xl">
    <div class="row g-2 align-items-center px-2">
      <div class="col-md-12">
        <h3>New Email Template</h3>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form #templateForm="ngForm">
            <div class="row">
              <div class="col-sm-6 mb-3">
                <label class="form-label required">Email Template Name</label>
                <input type="text" class="form-control" [(ngModel)]="emailTemplate.templateName"
                  name="name" #name="ngModel" placeholder="Input placeholder" required>
                <p class="invalid-feedback" *ngIf="(name.touched || name.dirty|| submitClicked) && name.invalid">
                    Please enter a template name</p>
              </div>
              <div class="col-sm-6 mb-3">
                <label class="form-label required">Description</label>
                <input type="text" class="form-control" [(ngModel)]="emailTemplate.description"
                  name="description"  #description="ngModel" placeholder="Input placeholder">
                <p class="invalid-feedback" *ngIf="(description.touched || description.dirty|| submitClicked) && description.invalid">
                    Please enter a description</p>
              </div>
              <div class="col-sm-6 mb-3">
                <label class="form-label">Language</label>
                <select class="form-select" name="language">
                  <option selected hidden disabled value="">Choose Language</option>
                  <option *ngFor="let language of languageDetails" [value]="language.code">{{language.name}}</option>
                </select>
              </div>
              <div class="col-sm-6 mb-3">
                <label class="form-label required">Add Tag</label>
                <ng-select  required [items]="tagsTemp" bindLabel="key" bindValue="value" id="tags" class="newfont custom-ng-select" [multiple]="true"
                  [(ngModel)]="emailTemplate.tags" placeholder="Add tag and press enter" name="tags"
                  #tags="ngModel"
                  >
                </ng-select>
                <p class="invalid-feedback" *ngIf="(tags.touched || tags.dirty|| submitClicked) && tags.invalid">
                  Please enter a tag</p>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-6">
                <div class="row w-100">
                  <div class="col-md-12">
                    <h4 class="w-auto float-left sma">Select type of email content</h4>
                    <div class="fright w-auto">
                      <a (click)="switchEmailEditor = !switchEmailEditor" class="btn btn-outline-primary  mr-1"
                        style="width: 93px;">
                        <i class="ti mr-1" [ngClass]="{'ti-edit':switchEmailEditor,'ti-eye': !switchEmailEditor}"></i>
                        {{switchEmailEditor? 'Change' : 'Preview'}}
                      </a>
                      <a class="btn btn-outline-primary" style="width: 30px;">
                        <i class="ti ti-zoom-in"></i>
                      </a>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <div>
                        <label class="form-check form-check-inline">
                          <input class="form-check-input" name="contentType" [(ngModel)]="emailTemplate.contentType" [value]="type.TEXT"
                            type="radio">
                          <span class="form-check-label">Text</span>
                        </label>
                        <label class="form-check form-check-inline">
                          <input class="form-check-input" name="contentType" [(ngModel)]="emailTemplate.contentType" [value]="type.HTML"
                            type="radio" >
                          <span class="form-check-label">HTML</span>
                        </label>
                        <label class="form-check form-check-inline">
                          <input class="form-check-input" name="contentType" [(ngModel)]="emailTemplate.contentType"
                            [value]="type.PATH_HTML" type="radio" name="radios-inline">
                          <span class="form-check-label">Upload HTML File</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="mb-3">
                      
                      <label class="form-label required">
                        Enter email content </label>
                        <!-- <p class="invalid-feedback" *ngIf="(tags.touched || tags.dirty|| submitClicked) && tags.invalid">
                          Please enter content</p> -->
                      <textarea [disabled]="switchEmailEditor" [(ngModel)]="emailTemplate.content" name="contentText"
                        *ngIf="emailTemplate.contentType =='TEXT'" rows="5" class="form-control"
                        data-bs-toggle="autosize" placeholder=""
                        style="overflow: hidden; overflow-wrap: break-word; resize: none; height: 300px;"></textarea>
                      <ckeditor [disabled]="switchEmailEditor" [(ngModel)]="emailTemplate.content"
                        *ngIf="emailTemplate.contentType =='HTML'" [editor]="ckEditor" name="contentHtml"></ckeditor>
                      <app-file-upload *ngIf="emailTemplate.contentType =='PATH_HTML' && !switchEmailEditor"
                        (fileSelected)="processImage($event)" [selected]="getFile()"
                        [currentFile]="'html'"></app-file-upload>
                      <div *ngIf="emailTemplate.contentType =='PATH_HTML' && switchEmailEditor" class="row"
                        style="height:300px; overflow:scroll">
                        <iframe [src]="getPreviewFrame()" width="100%" height="100%" frameborder="0"
                          allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>

                </div>


              </div>





              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="w-100 float-left">Email Details</h4>

                  </div>


                  <div class="mb-2">
                    <label class="form-label required txtsmall">
                      From Name</label>
                    <input [(ngModel)]="emailTemplate.from.name" type="text" name="example-text-input"
                      placeholder="Input placeholder" class="form-control">
                  </div>


                  <div class="mb-2">
                    <label class="form-label required txtsmall">
                      From Email</label>
                    <input [(ngModel)]="emailTemplate.from.emailId" type="text" name="example-text-input"
                      placeholder="Input placeholder" class="form-control">
                  </div>

                  <div class="mb-2">
                    <label class="form-label required txtsmall">
                      Subject</label>
                    <input [(ngModel)]="emailTemplate.subject" type="text" name="example-text-input"
                      placeholder="Input placeholder" class="form-control">
                  </div>
                  <div class="mb-2">
                    <label class="form-label txtsmall">
                      Reply to Name</label>
                    <input [(ngModel)]="emailTemplate.replyTo.name" type="text" name="example-text-input"
                      placeholder="Input placeholder" class="form-control">
                  </div>

                  <div class="mb-2">
                    <label class="form-label txtsmall">
                      Reply To</label>
                    <input [(ngModel)]="emailTemplate.replyTo.emailId" type="text" name="example-text-input"
                      placeholder="Input placeholder" class="form-control">
                  </div>

                </div>

              </div>

            </div>
            <div class="row mt-2">
              <hr />
            </div>
          </form>
            <div class="row mt-2">
              <div class="col-md-12">
                <a (click)="saveEmailTemplate()" class="btn btn-primary float-right w-auto">
                  Save
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>



  </div>
</div>