import { FMonthlyClosure } from './../models/fy-monthly-closure.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiResponse, BaseFilter, HKResponse } from 'src/app/core/models/common.models';
import { BaseService } from 'src/app/core/services/base.service';
import { BudgetCommonReportRequest, PlSummary } from '../models/budget.models';
import { CommonRatiosDto, IncomeExpenseOverview, KPIs, LegalEntity, OmmitenceData, OperationalStatement, RRPOverview, RRPStatement } from '../models/budget.pnl.models';
import { Sites } from '../../auth/models/property.models';

@Injectable({
  providedIn: 'root'
})
export class PnlService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  getRRPOverview(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<RRPOverview>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-budget/rrp-overview`,payload
     
    );

  }

  getRRPStatement(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<RRPStatement>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-statement/rrp-overview`,payload
    );

  }

  getIncomeStatementOverview(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<IncomeExpenseOverview[]>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-statement/income-group-overview`,payload
    );

  }

  getExpenseStatementOverview(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<IncomeExpenseOverview[]>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-statement/expense-group-overview`,payload
    );
  }

  getIncomeBudgetOverview(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<IncomeExpenseOverview[]>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-budget/income-group-overview`,payload
    );

  }

  getExpenseBudgetOverview(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<IncomeExpenseOverview[]>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-budget/expense-group-overview`,payload
    );

  

  }

  getPnlRatios(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<CommonRatiosDto[]>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-statement/ratios`,payload
    );
  }
  getPnlBudgetRatios(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<CommonRatiosDto[]>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-budget/ratios`,payload
    );
  }


  getYTDStatement(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<PlSummary>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-statement/statement-overview`,payload
    );
  }
  getYTDOverview(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<PlSummary>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-budget/budget-overview`,payload
    );
  }


  getLegalEntities(payload:BaseFilter,){
    return this.http.post<ApiResponse<LegalEntity[]>>(
      `${this.gemURL}/legal/dropdown`,payload
    );
  }

  getInternalFilteredSites(payload:BaseFilter){
    return this.http.post<ApiResponse<Sites[]>>(
      `${this.gemURL}/internal/property/info`,payload
    );
  }

  getOperationalIncomeStatement(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<OperationalStatement[]>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-statement/income-statements`,payload
    );
  }

  getOperationalExpenseStatement(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<OperationalStatement[]>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-statement/expense-statements`,payload
    );
  }

  getOperationalExpenseBudget(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<OperationalStatement[]>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-budget/expense-statements`,payload
    );
  }

  getOperationalIncomeBudget(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<OperationalStatement[]>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-budget/income-statements`,payload
    );
  }

  getKPIs(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<KPIs>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-statement/kpis`,payload
    );
  }

  getOmmitenceStatus(payload:BudgetCommonReportRequest,companyId:string){
    return this.http.post<ApiResponse<OmmitenceData[]>>(
      `${this.reportV2URL}report/v2/${companyId}/operational-statement/omittance`,payload
    );
  }

  
  












}
