export const environment = {
  production: false,
  gemURL: 'https://demo.gem.instio.co/api',
  oldSiteURL: 'https://demo.gem.instio.co',
  communicationURL: 'https://demo.communication.instio.co/',
  imgURL: 'https://demo.gem.instio.co/',
  workorderUrl: 'https://demo.wo.instio.co/wo/',
  woUrl: 'https://demo.wo.instio.co/',
  reviewURL: 'https://demo.feed.instio.co/api',
  crmURl: 'https://demo.crm.instio.co/',
  reportURL: 'https://demo.report.instio.co/',
  reportV2URL: 'https://demo.report.v2.instio.co/',
  hkUrl: 'https://demo.hk.instio.co',
  docUrl: 'https://demo.docs.instio.co/',
  taskUrl: 'https://demo.task.instio.co/',
  unLayerUrl: 'https://api.unlayer.com/v2',

  loyaltyUrl: 'https://demo.loyalty.instio.co/',
  pmsURL: 'https://demo.pms.instio.co/api/pms/v2',
  segmentsURL: 'https://demo.crm.instio.co/crm/v3',
  logBook: 'https://demo.logbook.instio.co/',
  auditUrl: 'https://demo.audits.instio.co/api',
  budgetUrl: 'https://demo.budget.instio.co/api',
  // https://demo.budget.instio.co/api
  authUrl:
    'UYn9Vq5eqNaVzVQIIXTaNdd1ZCrfGWvdBswTjuNvuL8t0qr9X28nXpoFRmGiihlChBcs9ChFOtedHwGd',
  marketingURL: 'https://demo.marketing.instio.co/api',
  eventURL: 'https://demo.events.instio.co/api/',
  accountURL: 'https://demo.accounts.instio.co/api/',

  googleSignInURL: 'https://demo.feed.instio.co/gr',
  chatBotURL: 'https://demo.integration.instio.co/cogniswitch',
  version: '3.5',
  // https://demo.budget.instio.co/api
  // https://demo.budget.instio.co/api

  syncFusionKey:
    'Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhccnRWRWNZWEByWUM=',
};
