<!-- <div>
    
    <div class="reviewers d-flex align-items-center" >
    <ng-container *ngIf="userAvatarInfoList?.length>0">
    <div class="avatar-list avatar-list-stacked" style="margin-right: 0.5rem;">  
        <span *ngIf="userAvatarInfoList[0]" class="avatar avatar-sm rounded-circle"><ngx-avatar [src]="getUserImage()"
                [size]="size" [textSizeRatio]="textSizeRatio" [initialsSize]="initialsSize"
                [matTooltip]="userAvatarInfoList[0]?.displayName" matTooltipPosition="above"
                [name]="userAvatarInfoList[0]?.displayName" class="my-img"></ngx-avatar>
    
            <span class="delete-icon" *ngIf="editable"
                (click)="removeUser(userAvatarInfoList[0]._id)">
                <i class="ti ti-circle-minus"></i>
            </span>
        </span>
    
        <span *ngIf="userAvatarInfoList[1]" class="avatar avatar-sm rounded-circle"><ngx-avatar [src]="getUserImage()"
                [size]="size" [textSizeRatio]="textSizeRatio" [initialsSize]="initialsSize"
                [matTooltip]="userAvatarInfoList[1]?.displayName" matTooltipPosition="above"
                [name]="userAvatarInfoList[1]?.displayName" class="my-img"></ngx-avatar>
            <span class="delete-icon" *ngIf="editable"
                (click)="removeUser(userAvatarInfoList[1]?._id)">
                <i class="ti ti-circle-minus"></i>
            </span>
        </span>
        <span *ngIf="userAvatarInfoList[2]" class="avatar avatar-sm rounded-circle"><ngx-avatar [src]="getUserImage()"
                [size]="size" [textSizeRatio]="textSizeRatio" [initialsSize]="initialsSize"
                [matTooltip]="userAvatarInfoList[2]?.displayName" matTooltipPosition="above"
                [name]="userAvatarInfoList[2]?.displayName" class="my-img"></ngx-avatar>
            <span class="delete-icon" *ngIf="editable"
                (click)="removeUser(userAvatarInfoList[2]?._id)">
                <i class="ti ti-circle-minus"></i>
            </span>
    
        </span>
        <span *ngIf="userAvatarInfoList.length>3"
            class="avatar avatar-sm rounded-circle toggle cursor-pointer"
            data-bs-toggle="dropdown">
            +
            {{userAvatarInfoList.length -3}}
        </span>
    
        <div class="dropdown-menu dropdown-menu-arrow">
            <ng-container *ngFor="let reviewer of userAvatarInfoList | slice:3;let i=index;">
                <a class="dropdown-item">
                    <span class="avatar avatar-sm rounded-circle"><ngx-avatar [src]="getUserImage()" size="30"
                            [textSizeRatio]="3" [initialsSize]="3"
                            [name]="reviewer?.displayName" class="my-img"></ngx-avatar>
                    </span> <span
                        style="margin-left: 1.5rem;">{{reviewer?.displayName}}</span>
                    <span class="delete-icon2" *ngIf="editable"
                        (click)="removeUser(reviewer?._id)">
                        <i class="ti ti-circle-minus"></i>
                    </span>
                </a>
            </ng-container>
        </div>
    </div>
</ng-container>
    <ng-container *ngIf="editable">
    <span class="avatar rounded-circle avatar-sm cursor-pointer ms-2" data-bs-toggle="dropdown"
        matTooltip="Add Reviewer" matTooltipPosition="above">
        <i class="ti ti-user-plus" style="font-size: 1rem;"></i>
    </span>
    <div class="dropdown-menu dropdown-menu-arrow drop-box">
        <a class="dropdown-item" *ngFor="let user of userList"
            (click)="addUser(user?.id)">
            <span class="avatar avatar-sm rounded-circle"><ngx-avatar [src]="getUserImage()" size="30"
                    [textSizeRatio]="3" [initialsSize]="3"
                    [name]="user?.displayName" class="my-img"></ngx-avatar>
            </span>
            <span style="margin-left: 1.5rem;">{{user?.displayName}}</span>
        </a>
    
    </div>
</ng-container>
    </div>
</div> -->




<div>
    <div class="reviewers d-flex align-items-center">
      <ng-container *ngIf="userAvatarInfoList?.length > 0">
        <div class="avatar-list avatar-list-stacked" style="margin-right: 0.5rem;">
          <ng-container *ngFor="let user of userAvatarInfoList; let i = index">
            <span class="avatar avatar-sm rounded-circle" *ngIf="i < 3">
              <ngx-avatar [src]="getUserImage(user?.userImage)" [size]="size" [textSizeRatio]="textSizeRatio" [initialsSize]="initialsSize"
                          [name]="user.displayName" class="my-img"></ngx-avatar>
              <span class="delete-icon" *ngIf="editable" (click)="removeUser(user._id)">
                <i class="ti ti-circle-minus"></i>
              </span>
            </span>
            <span *ngIf="i === 2 && userAvatarInfoList.length > 3"
                  class="avatar avatar-sm rounded-circle toggle cursor-pointer"
                  data-bs-toggle="dropdown">
              +{{ userAvatarInfoList.length - 3 }}
            </span>
            <div class="dropdown-menu dropdown-menu-arrow" *ngIf="userAvatarInfoList.length > 3">
                <ng-container *ngFor="let reviewer of userAvatarInfoList | slice:3">
                  <a class="dropdown-item">
                    <span class="avatar avatar-sm rounded-circle"><ngx-avatar [src]="getUserImage(reviewer?.userImage)" [size]="size" [textSizeRatio]="textSizeRatio" [initialsSize]="initialsSize"
                                                                            [name]="reviewer?.displayName" class="my-img"></ngx-avatar>
                    </span>
                    <span style="margin-left: 1.5rem;">{{ reviewer?.displayName }}</span>
                    <span class="delete-icon2" *ngIf="editable" (click)="removeUser(reviewer?._id)">
                      <i class="ti ti-circle-minus"></i>
                    </span>
                  </a>
                </ng-container>
              </div>
          </ng-container>
        </div>
      </ng-container>
  
      
  
      <ng-container *ngIf="editable">
        <span class="avatar rounded-circle avatar-sm cursor-pointer ms-2" data-bs-toggle="dropdown"
              matTooltip="Add Reviewer" matTooltipPosition="above">
          <i class="ti ti-user-plus" style="font-size: 1rem;"></i>
        </span>
        <div class="dropdown-menu dropdown-menu-arrow drop-box">
          <a class="dropdown-item" *ngFor="let user of userList" (click)="addUser(user?.id)">
            <span class="avatar avatar-sm rounded-circle"><ngx-avatar [src]="getUserImage(user?.userImage)" [size]="size" [textSizeRatio]="textSizeRatio" [initialsSize]="initialsSize"
                                                                    [name]="user?.displayName"
                                                                    class="my-img"></ngx-avatar>
            </span>
            <span style="margin-left: 1.5rem;">{{ user?.displayName }}</span>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
  
  
  
