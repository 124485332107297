import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-template',
  templateUrl: './error-template.component.html',
  styleUrls: ['./error-template.component.scss']
})
export class ErrorTemplateComponent implements OnInit{

  @Input() errorTitle:string;
  @Input() subTitle:string;
  @Input() hasErrorImage:boolean=true;

  ngOnInit(): void {
   
  }

}
