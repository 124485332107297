<span class="filter filter-pad bg-white nav-link cursor-pointer btn" style="margin-right: -8px;"
  data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false">
  <i class="ti ti-adjustments-horizontal fs-2" style="padding: 7px;"></i>
</span>
<ng-container>
  <div class="nav-item dropdown">
    <div class="dropdown-menu">
      <div class="dropend" *ngFor="let type of filterTypes">
        <a class="dropdown-item dropdown-toggle" (click)="fetchData(type)" href="#sidebar-cards"
          data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false">
          Any {{getDisplayString(type)}}
        </a>
        <div class="dropdown-menu">
          <!-- <div class="card card-borderless overflow-auto" style="max-height: 50vh;"> -->
          <ng-container *ngFor="let filter of filters[type]">
            <a class="dropdown-item " rel="noopener" *ngIf="filter.type == 'checkbox'">
              <div class="text d-flex justify-content-between align-items-center w-100 ">
                <div class="w-100 d-flex gap-2 align-items-center">
                  <label class="form-check cursor-pointer mb-0 w-100">
                    <input class="form-check-input cursor-pointer" (change)="onFilterChange(type, filter)"
                      [value]="filter?.value" [type]="filter?.type" [name]="type" [checked]="filter?.selected">
                    <span class="form-check-label ">{{filter?.label}}</span>
                  </label>
                </div>
              </div>
            </a>


            <a class="dropdown-item dropdown-toggle" *ngIf="filter.type === 'option'" href="#sidebar-cards"
              data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false">
              {{filter?.label}}
            </a>
            <a class="dropdown-item dropdown-toggle" *ngIf="filter.type === 'datepicker'" href="#sidebar-cards"
              data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false">
              {{filter?.label}}
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item w-100" rel="noopener" *ngFor="let option of filter?.subOptions">
                <label class="form-check form-check-inline mb-0">
                  <input [checked]="option.selected" (change)="onSubFilterChange(type, filter?.value ,option)"
                    [value]="option?.value" class="form-check-input" [type]="option.type" [name]="type"
                    [checked]="option?.selected">
                  <span class="form-check-label">{{option?.label}}</span>
                </label>
              </a>
              <a class="dropdown-item w-100" rel="noopener" *ngIf="filter.type === 'datepicker'">
                <mat-form-field class="due-date " style="max-width: 198px; max-height: 30px;" appearance="outline">
                  <input matInput [matDatepicker]="picker" [max]="frequencySelected?.frequencyTo"
                    [min]="frequencySelected?.frequencyFrom" name="month" [formControl]="dateSelected" required
                    readonly>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker disabled="false" startView="multi-year"
                    (yearSelected)="chosenYearHandler($event, picker)" (monthSelected)="setMonthAndYear($event, picker)"
                    panelClass="example-month-picker"></mat-datepicker>

                </mat-form-field>
                <span (click)="clearDate()" class="cursor-pointer"><i
                    class="ti ti-x fs-3 cursor-pointer fw-bold ms-1 me-2"></i></span>

              </a>
            </div>
          </ng-container>


          <!-- </div> -->

        </div>
      </div>
      <!-- <div class="dropend">
          <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
            data-bs-auto-close="outside" role="button" aria-expanded="false">
            Any Ownership Type
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item w-100" rel="noopener" *ngFor="let type of ownerShipFilters">
              <label class="form-check form-check-inline mb-0">
                <input class="form-check-input" [checked]="isCheckedOwnership(type?.value)" type="checkbox" (click)="onOwnershipTypeChange(type?.value)" name="radios-inline2" [value]="type?.value">
                <span class="form-check-label">{{type?.label}}</span>
              </label>
            </a>
           

          </div>
        </div>

        <div class="dropend">
          <a class="dropdown-item dropdown-toggle" (click)="getLegalEntities()" href="#sidebar-cards" data-bs-toggle="dropdown"
            data-bs-auto-close="outside" role="button" aria-expanded="false">
            Any Legal Name
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item w-100" rel="noopener" *ngFor="let entity of legalEntityList">
              <label class="form-check form-check-inline mb-0">
                <input class="form-check-input"  [checked]="isCheckedLegalEntity(entity?.id)" (click)="onLegalEntityChange(entity?.id)" type="checkbox" name="radios-inline2" [value]="entity?.id">
                <span class="form-check-label">{{entity?.name}}</span>
              </label>
            </a>
            

          </div>
        </div>

        <div class="dropend">
          <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
            data-bs-auto-close="outside" role="button" aria-expanded="false">
            Any Date
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
              data-bs-auto-close="outside" role="button" aria-expanded="false">
              Year Wise
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item w-100" rel="noopener">
                <label class="form-check form-check-inline mb-0">
                  <input (change)="datefilterChange('WHOLE_YEAR')" [checked]="advancedDateFilterType=='WHOLE_YEAR'" class="form-check-input" type="radio" name="radios-inline2" value="WHOLEYEAR">
                  <span class="form-check-label">Whole Year</span>
                </label>
              </a>
            </div>
            <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
              data-bs-auto-close="outside" role="button" aria-expanded="false">
              Half Yearly
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item w-100" rel="noopener">
                <label class="form-check form-check-inline mb-0">
                  <input (change)="datefilterChange('H1')" [checked]="advancedDateFilterType=='H1'" class="form-check-input" type="radio" name="radios-inline2" value="H1">
                  <span class="form-check-label">H1</span>
                </label>
              </a>
              <a class="dropdown-item w-100" rel="noopener">
                <label class="form-check form-check-inline mb-0">
                  <input (change)="datefilterChange('H2')" [checked]="advancedDateFilterType=='H2'"  class="form-check-input" type="radio" name="radios-inline2" value="H2">
                  <span class="form-check-label">H2</span>
                </label>
              </a>
            </div>
            <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
              data-bs-auto-close="outside" role="button" aria-expanded="false">
              Quarterly
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item w-100" rel="noopener">
                <label class="form-check form-check-inline mb-0">
                  <input (change)="datefilterChange('Q1')" [checked]="advancedDateFilterType=='Q1'" class="form-check-input" type="radio" name="radios-inline2" value="Q1">
                  <span class="form-check-label">Q1</span>
                </label>
              </a>
              <a class="dropdown-item w-100" rel="noopener">
                <label class="form-check form-check-inline mb-0">
                  <input (change)="datefilterChange('Q2')" [checked]="advancedDateFilterType=='Q2'"  class="form-check-input" type="radio" name="radios-inline2" value="Q2">
                  <span class="form-check-label">Q2</span>
                </label>
              </a>
              <a class="dropdown-item w-100" rel="noopener">
                <label class="form-check form-check-inline mb-0">
                  <input (change)="datefilterChange('Q3')" [checked]="advancedDateFilterType=='Q3'"  class="form-check-input" type="radio" name="radios-inline2" value="Q3">
                  <span class="form-check-label">Q3</span>
                </label>
              </a>
              <a class="dropdown-item w-100" rel="noopener">
                <label class="form-check form-check-inline mb-0">
                  <input (change)="datefilterChange('Q4')" [checked]="advancedDateFilterType=='Q4'"  class="form-check-input" type="radio" name="radios-inline2" value="Q4">
                  <span class="form-check-label">Q4</span>
                </label>
              </a>
            </div>
            <a class="dropdown-item dropdown-toggle" href="#sidebar-cards" data-bs-toggle="dropdown"
              data-bs-auto-close="outside" role="button" aria-expanded="false">
              Monthwise
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item w-100" rel="noopener">
                <mat-form-field class="due-date " style="max-width: 198px; max-height: 30px;" appearance="outline">
                  <input matInput  [matDatepicker]="picker" [max]="frequencySelected?.frequencyTo" [min]="frequencySelected?.frequencyFrom" name="month" [formControl]="dateSelected"
                      required readonly>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker disabled="false" startView="multi-year" (yearSelected)="chosenYearHandler($event, picker)"
                      (monthSelected)="setMonthAndYear($event, picker)"
                      panelClass="example-month-picker"></mat-datepicker>
                  
              </mat-form-field>
              <span (click)="clearDate()" class="cursor-pointer"><i class="ti ti-x fs-3 cursor-pointer fw-bold ms-1 me-2" ></i></span>
              
              </a>                   

            </div>


          </div>
        </div> -->

    </div>
  </div>
</ng-container>