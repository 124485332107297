<ng-container>
    <div>
        <div [ngStyle]="{'width': width ? width+'px' : '100%'}">
            <ng-select [(ngModel)]="selectedProperty" placeholder="Select a property" (change)="changeProperty()">
                <ng-option value="ALL" *ngIf="allRequired">
                    ALL
                </ng-option>
                <ng-option *ngFor="let property of properties" value="{{property._id}}">
                    {{property.entityName}}
                </ng-option>
            </ng-select>
        </div>
    </div>
    
</ng-container>