import { Component, Input, OnInit } from '@angular/core';
import { UserDataService } from '../../service/user-data.service';

@Component({
  selector: 'app-user-popover',
  templateUrl: './user-popover.component.html',
  styleUrls: ['./user-popover.component.scss']
})
export class UserPopoverComponent{

  @Input() user: any;

  
  
}
