<!-- <div class="dropdown-menu dropdown-menu-arrow" style="width: 320px;"> -->
    <ng-container >
        <div class="d-flex" *ngIf="(userOption && teamOption) || (userOption && propertyOption) || (teamOption && propertyOption) || (userOption && teamOption && propertyOption)">
            <a (click)="getUsers();dropDownSwitch('USER');$event.stopPropagation()"
                class="card-btn drop-btn" *ngIf="userOption">
                <i class=" border-icon ti ti-user  cursor-pointer"></i>
            </a>
            <a (click)="getTeams();dropDownSwitch('TEAM');$event.stopPropagation();"
                class="card-btn drop-btn" *ngIf="teamOption">
                <i class="border-icon ti ti-users-group  cursor-pointer"></i>
            </a>
            <a (click)="getProperties();dropDownSwitch('PROPERTY');$event.stopPropagation();"
                class="card-btn drop-btn" *ngIf="propertyOption">
                <i class="ti ti-building border-icon cursor-pointer"></i>
            </a>
            <a (click)="getBrands();dropDownSwitch('BRAND');$event.stopPropagation();"
                class="card-btn drop-btn" *ngIf="brandOption">
                <i class="ti ti-building border-icon cursor-pointer"></i>
            </a>
            <a (click)="getDepartments();dropDownSwitch('DEPARTMENT');$event.stopPropagation();"
            class="card-btn drop-btn" *ngIf="departmentOption">
            <i class="ti ti-tournament border-icon cursor-pointer"></i>
        </a>

        </div>
        <a class="dropdown-item">
            <input class="me-2 ms-2 m-2" value='' type="text"
                [placeholder]="getPlaceholderText()" #search class="form-control"
                (input)="findValue(search.value)">
        </a>
        <div class="drop-box"  [style.maxHeight.px]="dropdownHeight">
            <ng-container *ngIf="dropDownSwitchType=='USER'">
                <ng-container *ngIf="multiple; else singleUserSelect">
                    <div class="dropdown-item" [ngClass]="{'active':isCheckedUser(user.id)}" *ngFor="let user of findValue(search.value)" (click)="$event.stopPropagation();" >
                        <!-- <label class="form-check"> -->
                        <input class="form-check-input me-2" name="user" type="checkbox" [value]="user" [checked]="isCheckedUser(user.id)" (change)="toggleUserSelection($event.target['checked'], user);">
                        <!-- </label> -->
                        <div class="row align-items-center" style="width: 100%;">
                            <div class="col-auto">
                                <ngx-avatar size="30" [textSizeRatio]="3" [initialsSize]="3" [name]="user.displayName"
                                    class="my-img"></ngx-avatar>
                            </div>
                            <div class="col">
                                <div class="fw-bold fs-5" style="white-space: break-spaces;">
                                    {{user?.displayName}}
                
                                </div>
                                <div class="fs-5 text-truncate">
                                    {{user?.email?.emailId}}</div>
                                <div
                                    class="tags-list text-secondary fs-6 flex-wrap d-flex align-items-center justify-content-start gap-1">
                                    <span class="tag d-flex align-items-center " *ngIf="user?.departmentName"><i
                                            class="ti ti-tournament me-1"></i><span
                                            class="text-truncate">{{user?.departmentName}}</span></span>
                                    <span class="tag d-flex align-items-center " *ngIf="user?.positionName"><i
                                            class="ti ti-user-pin me-1"></i><span
                                            class="text-truncate">{{user?.positionName}}</span></span>
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="avatar-list avatar-list-stacked">
                                    <span class="avatar avatar-sm rounded-circle" *ngFor="let entity of user?.userEntities">  {{entity?.entityCode}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- Display user information here -->
                    </div>
                </ng-container>
                <ng-template #singleUserSelect >
                    <div class="dropdown-item cursor-pointer" [ngClass]="{'active':isCheckedUser(user.id)}" *ngFor="let user of findValue(search.value)" (click)="userSelected(user);search.value = ''">
                        <!-- Display user information here -->
                        <div class="row align-items-center" style="width: 100%;">
                            <div class="col-auto">
                                <ngx-avatar size="30" [textSizeRatio]="3" [initialsSize]="3" [name]="user.displayName"
                                    class="my-img"></ngx-avatar>
                            </div>
                            <div class="col">
                                <div class="fw-bold fs-5" style="white-space: break-spaces;">
                                    {{user?.displayName}}
                
                                </div>
                                <div class="fs-5 text-truncate">
                                    {{user?.email?.emailId}}</div>
                                <div
                                    class="tags-list text-secondary fs-6 flex-wrap d-flex align-items-center justify-content-start gap-1">
                                    <span class="tag d-flex align-items-center " *ngIf="user?.departmentName"><i
                                            class="ti ti-tournament me-1"></i><span
                                            class="text-truncate">{{user?.departmentName}}</span></span>
                                    <span class="tag d-flex align-items-center " *ngIf="user?.positionName"><i
                                            class="ti ti-user-pin me-1"></i><span
                                            class="text-truncate">{{user?.positionName}}</span></span>
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="avatar-list avatar-list-stacked">
                                    <span class="avatar avatar-sm rounded-circle" *ngFor="let entity of user?.userEntities">  {{entity?.entityCode}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!-- <a class="dropdown-item cursor-pointer" *ngFor="let user of findValue(search.value)"
                    (click)="userSelected(user);search.value = ''">
                    <div class="row align-items-center" style="width: 100%;">
                        <div class="col-auto">
                            <ngx-avatar size="30" [textSizeRatio]="3" [initialsSize]="3" [name]="user.displayName"
                                class="my-img"></ngx-avatar>
                        </div>
                        <div class="col">
                            <div class="fw-bold fs-5" style="white-space: break-spaces;">
                                {{user?.displayName}}
            
                            </div>
                            <div class="fs-5 text-truncate">
                                {{user?.email?.emailId}}</div>
                            <div
                                class="tags-list text-secondary fs-6 flex-wrap d-flex align-items-center justify-content-start gap-1">
                                <span class="tag d-flex align-items-center " *ngIf="user?.departmentName"><i
                                        class="ti ti-tournament me-1"></i><span
                                        class="text-truncate">{{user?.departmentName}}</span></span>
                                <span class="tag d-flex align-items-center " *ngIf="user?.positionName"><i
                                        class="ti ti-user-pin me-1"></i><span
                                        class="text-truncate">{{user?.positionName}}</span></span>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="avatar-list avatar-list-stacked">
                                <span class="avatar avatar-sm rounded-circle" *ngFor="let entity of user?.userEntities">  {{entity?.entityCode}}</span>
                            </div>
                        </div>
                    </div>
                </a> -->
                <a *ngIf="otherUserOption" class="dropdown-item" (click)="otherSelected();search.value = ''">
                    <span class="avatar avatar-sm rounded-circle"><ngx-avatar facebookId="1508319875" size="30" [textSizeRatio]="3"
                            [initialsSize]="3" class="my-img"></ngx-avatar>
                    </span>
            
                    <span style="margin-left: 1rem;">Other</span>
                </a>
            </ng-container>

            <ng-container *ngIf="dropDownSwitchType=='TEAM'">
                <ng-container *ngIf="multiple; else singleTeamSelect">
                    <a class="dropdown-item" *ngFor="let team of findValue(search.value) " (click)="teamSelected(team);search.value = '';$event.stopPropagation();">
                    <input class="form-check-input me-2" name="team" type="checkbox" [value]="team"  (change)="toggleTeamSelection($event.target['checked'], team);">
                    <div class="row align-items-center w-100" >
                        <div class="col-auto">
                            <span class="avatar rounded-circle"><i
                                    class=" fs-3 ti ti-users-group  cursor-pointer"></i></span>
                        </div>
                        <div class="col">
                            <div class="font-weight-medium">
                                {{team?.name}}
                            </div>
                            <div class="text-secondary">
                                Users
                            </div>
                        </div>
                        <div class="col-auto">
                            <span class="avatar avatar-sm rounded-circle" *ngIf="team.siteId"><app-location-info [shortForm]="true" [siteId]="team?.siteId"></app-location-info></span>
    
                        </div>
                    </div>
                </a>
                </ng-container>
                <ng-template #singleTeamSelect>
                    <a class="dropdown-item" [ngClass]="{'active':isCheckedTeam(team.id)}"  *ngFor="let team of findValue(search.value)"
                    (click)="teamSelected(team);search.value = ''">
                    <div class="row align-items-center w-100" >
                        <div class="col-auto">
                            <span class="avatar rounded-circle"><i
                                    class=" fs-3 ti ti-users-group  cursor-pointer"></i></span>
                        </div>
                        <div class="col">
                            <div class="font-weight-medium">
                                {{team?.name}}
                            </div>
                            <div class="text-secondary">
                                Users
                            </div>
                        </div>
                        <div class="col-auto">
                            <span class="avatar avatar-sm rounded-circle" *ngIf="team.siteId"><app-location-info [shortForm]="true" [siteId]="team?.siteId"></app-location-info></span>
    
                        </div>
                    </div>
                </a>
                </ng-template>

                <!-- <a class="dropdown-item" *ngFor="let team of findValue(search.value)"
                    (click)="teamSelected(team);search.value = '';$event.stopPropagation();" >
                    <div class="row align-items-center w-100" >
                        <div class="col-auto">
                            <span class="avatar rounded-circle"><i
                                    class=" fs-3 ti ti-users-group  cursor-pointer"></i></span>
                        </div>
                        <div class="col">
                            <div class="font-weight-medium">
                                {{team?.name}}
                            </div>
                            <div class="text-secondary">
                                Users
                            </div>
                        </div>
                        <div class="col-auto">
                            <span class="avatar avatar-sm rounded-circle" *ngIf="team.siteId"><app-location-info [shortForm]="true" [siteId]="team?.siteId"></app-location-info></span>
    
                        </div>
                    </div>
                </a> -->
            </ng-container>

            <ng-container *ngIf="dropDownSwitchType=='PROPERTY'">
                <ng-container *ngIf="findValue(search.value)?.length > 0">
                    <ng-container *ngIf="multiple; else singlePropertySelect">
                        <a class="dropdown-item cursor-pointer" (click)="handleClick($event, property)" [ngClass]="{'active':isCheckedProperty(property._id)}" *ngFor="let property of findValue(search.value)">
                            
                            <input class="form-check-input me-2" type="checkbox" name="property" [value]="property" [checked]="isCheckedProperty(property._id)"  (change)="$event.stopPropagation(); togglePropertySelection($event.target['checked'], property);">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col-auto">
                                    <ngx-avatar size="30" *ngIf="!property?._id" [textSizeRatio]="3" [initialsSize]="3" [bgColor]="'#fff'" [name]="property.entityName"
                                        class="my-img"></ngx-avatar>
                                    <ngx-avatar size="30" *ngIf="property?._id && property?.entityCode" [textSizeRatio]="3" [initialsSize]="3" [value]="property?.entityCode"
                                        class="my-img"></ngx-avatar>
                                    <ngx-avatar size="30" *ngIf="property?._id && !property?.entityCode" [textSizeRatio]="3" [initialsSize]="3" [name]="property?.entityName"
                                        class="my-img"></ngx-avatar>
                                </div>
                                <div class="col">
                                    <div class="fw-bold fs-5" style="white-space: break-spaces;">
                                        {{property.entityName}}
                                    </div>                     
                                </div>                            
                            </div>
                
                            
                        </a>
                    </ng-container>
                    <ng-template #singlePropertySelect>
                        <a class="dropdown-item cursor-pointer" [ngClass]="{'active':isCheckedProperty(property._id)}"  *ngFor="let property of findValue(search.value)" 
                        (click)="propertySelected(property);search.value = ''">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col-auto">
                                    <ngx-avatar size="30" *ngIf="!property?._id" [textSizeRatio]="3" [initialsSize]="3" [bgColor]="'#fff'" [name]="property.entityName"
                                        class="my-img"></ngx-avatar>
                                    <ngx-avatar size="30" *ngIf="property?._id && property?.entityCode" [textSizeRatio]="3" [initialsSize]="3" [value]="property?.entityCode"
                                        class="my-img"></ngx-avatar>
                                    <ngx-avatar size="30" *ngIf="property?._id && !property?.entityCode" [textSizeRatio]="3" [initialsSize]="3" [name]="property?.entityName"
                                        class="my-img"></ngx-avatar>
                                </div>
                                <div class="col">
                                    <div class="fw-bold fs-5" style="white-space: break-spaces;">
                                        {{property.entityName}}
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-template>         
                </ng-container>

                <ng-container *ngIf="findValue(search.value)?.length == 0">
                    <div class="card card-borderless">
                        <div class="card-body">
                            <div class="card-title text-center">
                                No Properties
                            </div>
                        </div>
                    </div>
                    
                </ng-container>
            </ng-container>
            <ng-container *ngIf="dropDownSwitchType=='BRAND'">
                <ng-container *ngIf="findValue(search.value)?.length > 0">
                    <ng-container *ngIf="multiple; else singleBrandSelect">
                        <a class="dropdown-item cursor-pointer" *ngFor="let brand of findValue(search.value)" 
                        (click)="brandSelected(brand);search.value = ''">
                        <input class="form-check-input me-2" type="checkbox" name="brand" [value]="brand"  (change)="toggleBrandSelection($event.target['checked'], brand);">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col-auto">
                                    <ngx-avatar size="30" *ngIf="!brand?.id" [textSizeRatio]="3" [initialsSize]="3" [bgColor]="'#fff'" [name]="brand.name"
                                    class="my-img"></ngx-avatar>
                                <ngx-avatar size="30" *ngIf="brand?.id" [textSizeRatio]="3" [initialsSize]="3" [name]="brand.name"
                                    class="my-img"></ngx-avatar>
                                </div>
                                <div class="col">
                                    <div class="fw-bold fs-5" style="white-space: break-spaces;">
                                        {{brand.name}}
                                    </div>                     
                                </div>                            
                            </div>
                        </a>
                    </ng-container>
                    <ng-template #singleBrandSelect>
                        <a class="dropdown-item cursor-pointer" [ngClass]="{'active':isCheckedBrand(brand.id)}" *ngFor="let brand of findValue(search.value)" 
                        (click)="brandSelected(brand);search.value = ''">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col-auto">
                                    <ngx-avatar size="30" *ngIf="!brand?.id" [textSizeRatio]="3" [initialsSize]="3" [bgColor]="'#fff'" [name]="brand.name"
                                        class="my-img"></ngx-avatar>
                                    <ngx-avatar size="30" *ngIf="brand?.id" [textSizeRatio]="3" [initialsSize]="3" [name]="brand.name"
                                        class="my-img"></ngx-avatar>
                                </div>
                                <div class="col">
                                    <div class="fw-bold fs-5" style="white-space: break-spaces;">
                                        {{brand.name}}
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-template>         
                </ng-container>

                <ng-container *ngIf="findValue(search.value)?.length == 0">
                    <div class="card card-borderless">
                        <div class="card-body">
                            <div class="card-title text-center">
                                No Properties
                            </div>
                        </div>
                    </div>
                    
                </ng-container>
            </ng-container>

            <ng-container *ngIf="dropDownSwitchType=='DEPARTMENT'">
                <ng-container *ngIf="findValue(search.value)?.length > 0">
                    <ng-container *ngIf="multiple; else singleDepartmentSelect">
                        <a class="dropdown-item cursor-pointer" *ngFor="let department of findValue(search.value)" 
                        (click)="departmentSelected(department);search.value = ''">
                        <input class="form-check-input me-2" type="checkbox" name="brand" [value]="department"  (change)="toggleDepartmentSelection($event.target['checked'], department);">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col-auto text-black">
                                    <ngx-avatar size="30" *ngIf="!department?._id" [textSizeRatio]="3" [initialsSize]="3" [name]="department.displayName"
                                    class="my-img"></ngx-avatar>
                                    <ngx-avatar size="30" *ngIf="department?._id" [textSizeRatio]="3" [initialsSize]="3" [name]="department.displayName"
                                    class="my-img"></ngx-avatar>
                                </div>
                                <div class="col">
                                    <div class="fw-bold fs-5" style="white-space: break-spaces;">
                                        {{department.displayName}}
                                    </div>                     
                                </div>                            
                            </div>
                        </a>
                    </ng-container>
                    <ng-template #singleDepartmentSelect>
                        <a class="dropdown-item cursor-pointer" [ngClass]="{'active':isCheckedDepartment(department._id)}" *ngFor="let department of findValue(search.value)" 
                        (click)="departmentSelected(department);search.value = ''">
                            <div class="row align-items-center" style="width: 100%;">
                                <div class="col-auto">
                                    <ngx-avatar size="30" *ngIf="!department?.id" [textSizeRatio]="3" [initialsSize]="3" [name]="department.displayName"
                                        class="my-img"></ngx-avatar>
                                    <ngx-avatar size="30" *ngIf="department?.id" [textSizeRatio]="3" [initialsSize]="3" [name]="department.displayName"
                                        class="my-img"></ngx-avatar>
                                </div>
                                <div class="col">
                                    <div class="fw-bold fs-5" style="white-space: break-spaces;">
                                        {{department.displayName}}
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-template>         
                </ng-container>

                <ng-container *ngIf="findValue(search.value)?.length == 0">
                    <div class="card card-borderless">
                        <div class="card-body">
                            <div class="card-title text-center">
                                No Properties
                            </div>
                        </div>
                    </div>
                    
                </ng-container>
            </ng-container>
            
        </div>
    
    </ng-container>
    
    <!-- </div> -->