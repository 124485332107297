<div class="modal-content">
  <div class="modal-body text-center py-4" *ngIf="modalType == 'DELETE' || !modalType">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24"
      viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
      stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 9v2m0 4v.01" />
      <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
    </svg>
    <h3 *ngIf="heading">{{heading}}</h3>
    <h3 *ngIf="!heading">Do you want to remove?</h3>
    <div class="text-muted" *ngIf="content">{{content}}</div>
  </div>

  <div class="modal-body text-center py-4" *ngIf="modalType == 'CONFORMATION'">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-orange icon-lg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 9v2m0 4v.01" />
      <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" />
    </svg>
    <h3 *ngIf="heading">{{heading}}</h3>
    <h3 *ngIf="!heading">Are you sure?</h3>
    <div class="text-muted" *ngIf="content">{{content}}</div>
  </div>
  <div class="modal-footer">
    <div class="w-100">
      <div class="row">
        <div class="col"><a (click)="modal.dismiss()" class="btn w-100" data-bs-dismiss="modal">
            Cancel
          </a></div>
        <div class="col">
          <a (click)="deleteModal()" class="btn btn-danger w-100" data-bs-dismiss="modal" *ngIf="modalType == 'DELETE' || !modalType">
            <!-- <span *ngIf="!buttonText">Remove</span> -->
            <span>Delete</span>

          </a>
          <a (click)="deleteModal()" class="btn btn-warning w-100" data-bs-dismiss="modal" *ngIf="modalType == 'CONFORMATION'">
            <span>Confirm</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>