<div>
  <div class="reviewers d-flex align-items-center">
    <ng-container *ngIf="userAvatarInfoList?.length > 0">
      <div class="avatar-list avatar-list-stacked" style="margin-right: 0.5rem;">
        <ng-container *ngFor="let user of userAvatarInfoList; let i = index">
          <span [matTooltip]="user?.displayName" matTooltipPosition="above" class="avatar avatar-sm rounded-circle"
            *ngIf="i < 3">
            <ngx-avatar [src]="getUserImage(user?.userImage)" [size]="size" [textSizeRatio]="textSizeRatio"
              [initialsSize]="initialsSize" [name]="user.displayName" class="my-img"></ngx-avatar>
            <span class="delete-icon" *ngIf="editable && user._id === currentUser" (click)="removeUser(user._id)">
              <i class="ti ti-circle-minus"></i>
            </span>
          </span>
          <span *ngIf="i === 2 && userAvatarInfoList.length > 3"
            class="avatar avatar-sm rounded-circle toggle cursor-pointer" data-bs-toggle="dropdown">
            +{{ userAvatarInfoList.length - 3 }}
          </span>
          <div class="dropdown-menu dropdown-menu-arrow" *ngIf="userAvatarInfoList.length > 3">
            <ng-container *ngFor="let reviewer of userAvatarInfoList | slice:3">
              <a class="dropdown-item">
                <span [matTooltip]="reviewer?.displayName" matTooltipPosition="above"
                  class="avatar avatar-sm rounded-circle"><ngx-avatar [src]="getUserImage(reviewer?.userImage)"
                    [size]="size" [textSizeRatio]="textSizeRatio" [initialsSize]="initialsSize"
                    [name]="reviewer?.displayName" class="my-img"></ngx-avatar>
                </span>
                <span style="margin-left: 1.5rem;">{{ reviewer?.displayName }}</span>
                <span class="delete-icon2" *ngIf="editable && reviewer._id === currentUser"
                  (click)="removeUser(reviewer?._id)">
                  <i class="ti ti-circle-minus"></i>
                </span>
              </a>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>