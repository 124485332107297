import { BehaviorSubject } from "rxjs";

export class SessionUtils {
    
    static setToSessionStorage(type: string, id: string, data: any): void {
        const key = this.getKey(type, id);
        sessionStorage.setItem(key, JSON.stringify(data));
      }
    
      static getFromSessionStorage(type: string, id: string): any | null {
        const key = this.getKey(type, id);
        const data = sessionStorage.getItem(key);
        return data ? JSON.parse(data) : null;
      }

      static setBehaviorSubjectToSessionStorage(type: string, id: string, data: any): void {
        const key = this.getKey(type, id);
        const valueToStore = data instanceof BehaviorSubject ? data.getValue() : data; // Only store _value if it's BehaviorSubject
        sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }
    

      static getKey(type: string, id: string): string {
        return `instio.v3.${type}.${id}`;
      }
    
}

