import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DepartmentDropdownResponse,
  Team,
  User,
} from 'src/app/modules/user/models/user.models';
import { Properties } from '../../models/notification.models';
import { BrandInfo } from 'src/app/core/services/property.service';

@Component({
  selector: 'app-user-property-filter-dropdown',
  templateUrl: './user-property-filter-dropdown.component.html',
  styleUrls: ['./user-property-filter-dropdown.component.scss'],
})
export class UserPropertyFilterDropdownComponent implements OnInit, OnChanges {
  @Input() siteId: string;
  @Input() siteIds: any[];
  @Input() selectFirstItem: boolean = false;
  // input already selected data to be shown in the dropdown
  @Input() selectedUserIds: string[] = []; //Accept all the selected userIds so that the dropdown won't show it again
  @Input() selectedTeamIds: string[] = []; //Accept all the selected teamIds so that the dropdown won't show it again
  @Input() selectedPropertytIds: string[] = [];
  @Input() selectedBrandIds: string[] = [];
  @Input() selectedDepartmentIds: string[] = [];
  @Input() divisionId: string;

  // check for type of dropdowns needed
  @Input() otherUserOption: boolean = false;
  @Input() userOption: boolean = false;
  @Input() teamOption: boolean = false;
  @Input() propertyOption: boolean = false;
  @Input() brandOption: boolean = false;
  @Input() departmentOption: boolean = false;
  @Input() includeCompanyAdmin: boolean = true;
  // style properties
  @Input() dropdownHeight: number = 355;
  @Input() filterWidth: number = 200;

  // cloud filter for site.which cloud the data should fetch from
  @Input() cloud: string;

  // multiselect enabled or not
  @Input() multiple: boolean = false;

  // check if all property option is needed
  @Input() hasAllPropertyOption: boolean = false;
  @Input() hasAllBrandOption: boolean = false;

  @Input() isValid : boolean;

  // emmit event when a value is selected in the dropdown
  @Output() userSelectedEvent = new EventEmitter<User | User[]>();
  @Output() teamSelectedEvent = new EventEmitter<Team | Team[]>();
  @Output() propertySelectedEvent = new EventEmitter<
    Properties | Properties[]
  >();
  @Output() brandSelectedEvent = new EventEmitter<BrandInfo | BrandInfo[]>();
  @Output() otherSelectedEvent = new EventEmitter<boolean>();
  @Output() departmentSelectedEvent = new EventEmitter<
    DepartmentDropdownResponse | DepartmentDropdownResponse[]
  >();

  public displaySelectedData: string;
  public placeHolder: string = 'Select User';
  public displaySelectedArray = [];
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['siteId']?.currentValue) {
      this.siteId = changes['siteId'].currentValue;
    }

    if (changes['divisionId']?.currentValue) {
      this.divisionId = changes['divisionId'].currentValue;
    }

    if (changes['selectedUserIds']?.currentValue) {
      this.selectedUserIds = changes['selectedUserIds'].currentValue;
    }
  }

  ngOnInit(): void {
    if (this.userOption) {
      this.placeHolder = 'Select User';
    } else if (this.teamOption) {
      this.placeHolder = 'Select Team';
    } else if (this.propertyOption) {
      this.placeHolder = 'Select Property';
    } else if (this.brandOption) {
      this.placeHolder = 'Select Brand';
    } else if (this.departmentOption) {
      this.placeHolder = 'Select Department';
    }

    // if (this.defaultSelectedProperty) {
    //   this.setDisplayDataProperty(this.defaultSelectedProperty)
    // }
  }

  emmitUserSelectedEvent(user: User | User[]) {
    this.setDisplayDataUser(user);
    this.userSelectedEvent.emit(user);
  }

  emmitTeamSelectedEvent(team: Team | Team[]) {
    this.setDisplayDataTeam(team);
    this.teamSelectedEvent.emit(team);
  }

  emmitPropertySelectedEvent(property: Properties | Properties[]) {
    this.setDisplayDataProperty(property);
    this.propertySelectedEvent.emit(property);
  }

  emmitBrandSelectedEvent(brand: BrandInfo | BrandInfo[]) {
    this.setDisplayDataBrand(brand);
    this.brandSelectedEvent.emit(brand);
  }

  emmitOtherSelectedEvent(event: boolean) {
    this.otherSelectedEvent.emit(event);
  }

  emmitDepartmentSelectedEvent(
    department: DepartmentDropdownResponse | DepartmentDropdownResponse[]
  ) {
    this.setDisplayDataDepartment(department);
    this.departmentSelectedEvent.emit(department);
  }

  setDisplayDataUser(user: User | User[]) {
    if (!Array.isArray(user)) {
      this.displaySelectedData = user.displayName;
    } else {
      this.displaySelectedData = user.map((u) => u.displayName).join(',');
      this.displaySelectedArray = user.map((u) => u.displayName);
    }
  }

  setDisplayDataTeam(team: Team | Team[]) {
    if (!Array.isArray(team)) {
      this.displaySelectedData = team.name;
    } else {
      this.displaySelectedData = team.map((t) => t.name).join(',');
    }
  }

  setDisplayDataProperty(property: Properties | Properties[]) {
    if (!Array.isArray(property)) {
      this.displaySelectedData = property?.entityName;
    } else {
      this.displaySelectedData = property.map((p) => p.entityName).join(',');
      this.displaySelectedArray = property.map((u) => u.entityName);
    }
  }

  setDisplayDataBrand(brand: BrandInfo | BrandInfo[]) {
    if (!Array.isArray(brand)) {
      this.displaySelectedData = brand.name;
    } else {
      this.displaySelectedData = brand.map((b) => b.name).join(',');
    }
  }

  setDisplayDataDepartment(
    department: DepartmentDropdownResponse | DepartmentDropdownResponse[]
  ) {
    if (!Array.isArray(department)) {
      this.displaySelectedData = department.displayName;
    } else {
      this.displaySelectedData = department.map((d) => d.displayName).join(',');
    }
  }
}
