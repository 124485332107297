<div class="sparkline row" *ngIf="value1!=null && value2 !=null"> 
    <div class="col-6 p-0 d-flex flex-column align-items-end justify-content-end cursor-pointer" >
      <div class="bars w-75  bg-indigo " [style.height]="getBarHeight(value1)"   >
      </div>
      <div class="fs-6"> Budg.</div> 
    </div>   
    <div class="col-6 p-0 d-flex flex-column align-items-end justify-content-end cursor-pointer" >
      <div class="bars w-75  bg-cyan " [style.height]="getBarHeight(value2)"  >
      </div>
      <div class="fs-6"> Act. </div>
    </div>  
  </div>